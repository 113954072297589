import { AuditLogAction, AuditLogIdentifier, RESULT_STATE } from "constant"
import { convertDateFormat, getFirstLetterOfEachWords, getOppositeColor, getUserInfoStorage, randomColor } from "helpers/utilities"
import moment from "moment"
import PropTypes from "prop-types"
import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import { getTestResultResultAuditLog } from "store/laboratory/testResult/actions"
import CustomResultAuditLogTable from "./CustomResultAuditLogTable"
import CustomModalLog from "./CustomModalLog"

const FieldNotCheckResult = ['CreatedBy', 'CreatedDate', 'UpdatedBy', 'UpdatedDate', 'ResultTestId', 'Id', 'ResultTestId', 'State', 'RequestId']
const CustomResultHistory = forwardRef(
    ({
        t,
        testResultHistory,
        onRefresh,
        identifier,
        type = '',
        module,
    }, ref) => {
        const [dataSource, setDataSource] = useState([])
        const currentUser = getUserInfoStorage("sub")
        const [dataPayload, setDataPayload] = useState({})
        const [modal, setModal] = useState(false)
        useEffect(() => {
            mappingHistory(testResultHistory)
        }, [testResultHistory])

        const checkFieldChange = (oldData, newData) => {
            let res = []
            for (const [key, value] of Object.entries(oldData)) {
                if (!FieldNotCheckResult.includes(key))
                    if (newData[key] != value) {
                        res.push(key)
                    }
            }
            return res
        }

        const firstKeyChange = (val) => {
            for (const [key, value] of Object.entries(val)) {
                if (!([...FieldNotCheckResult, 'InstanceId']).includes(key))
                    if (value != "")
                        return key
            }
            return ""
        }
        const checkType = (type1, type2) => {
            if (type) {
                type1 = type1.replace("General", "");
                type2 = type2.replace("General", "");
                return type1 == type2
            }
            else {
                return type1 == type2
            }
        }

        const mappingHistory = (vals) => {

            let res = [...vals];
            //cho payload thành object
            res = res.map(x => {
                let tmp = x
                try {
                    const pl = x.payload.replaceAll("ISODate(", "").replaceAll('Z")', '"').replaceAll("NumberLong(", "").replaceAll(")", "")
                    tmp.payload = JSON.parse(pl)
                } catch (error) {
                    tmp.payload = {}
                }
                return x
            })
            for (let index = 0; index < res.length; index++) {
                let element = res[index];
                if (AuditLogAction.Create == element.action || AuditLogAction.Created == element.action) {
                    element.message = `
                    <span class="font-weight-semibold">${t(element.action)} </span>
                    <span class="font-weight-semibold">${t(element.type)} </span>:<br/>`
                    try {
                        if (element?.type === 'Request') {
                            const keySkip = ["CompanyId", "ContractId", "CreatedBy", "DepartmentId", "State", "UpdatedBy", "UpdatedDate", "CreatedDate"]
                            let result = "{<br/>";
                            let isFirst = true;

                            for (const key of Object.keys(element.payload)) {
                                if (keySkip.includes(key)) {
                                    continue;
                                }
                                const isDateTimeKey = key === 'RequestDate'
                                if (isFirst) {
                                    if (isDateTimeKey) {
                                        const formattedDateTime = convertDateFormat(element.payload[key], "YYYY-MM-DD");

                                        result += `<span class="text-align">${key}: "${formattedDateTime}"</span>`;
                                    } else {
                                        result += `<span class="text-align" style={{ color: "blue" }}>${key}: "${t(element.payload[key])}"</span>`;
                                    }
                                    isFirst = false;
                                } else {
                                    if (isDateTimeKey) {
                                        const formattedDateTime = convertDateFormat(element.payload[key], "YYYY-MM-DD");
                                        result += `,<br/><span class="text-align">${key}: "${formattedDateTime}"</span>`;
                                    } else {
                                        result += `,<br/><span class="text-align" style={{ color: "blue" }}>${key}: "${t(element.payload[key])}"</span>`;
                                    }
                                }
                            }
                            result = result + "<br/>}";
                            element.message += result
                        } else if (element?.type === 'RequestPatient') {
                            const keySkip = ["AddressCountry", "CreatedBy", "CreatedDate", "FirstVisitCompanyId", "GivenName", "GroupCode", "Id", "ManagementCompanyId", "ProfileId", "Remark", "RequestId", "UpdatedBy", "UpdatedDate"]
                            let result = "{<br/>";
                            let isFirst = true;
                            for (const key of Object.keys(element.payload)) {
                                if (keySkip.includes(key)) {
                                    continue;
                                }
                                const isDateTimeKey = key === 'DOB'
                                if (isFirst) {
                                    if (isDateTimeKey) {
                                        const formattedDateTime = convertDateFormat(element.payload[key], "YYYY-MM-DD");

                                        result += `<span class="text-align">${key}: "${formattedDateTime}"</span>`;
                                    } else {
                                        result += `<span class="text-align">${key}: "${t(element.payload[key])}"</span>`;
                                    }
                                    isFirst = false;
                                } else {
                                    if (isDateTimeKey) {
                                        const formattedDateTime = convertDateFormat(element.payload[key], "YYYY-MM-DD");
                                        result += `,<br/><span class="text-align">${key}: "${formattedDateTime}"</span>`;
                                    } else {
                                        result += `,<br/><span class="text-align">${key}: "${t(element.payload[key])}"</span>`;
                                    }
                                }
                            }
                            result = result + "<br/>}";
                            element.message += result
                        }

                    } catch (error) {
                        console.log(error);
                    }
                }
                else if (AuditLogAction.Update == element.action || AuditLogAction.Updated == element.action) {
                    element.message = `
                    <span class="font-weight-semibold">${t(element.action)}</span>
                    <span class="font-weight-semibold">${t(element.type)}</span>:<br/>`
                    try {
                        let tmp = element.payload
                        if (AuditLogIdentifier.LA_TestRequest && (`${element.type}`.toLocaleLowerCase().includes("test") || `${element.type}`.toLocaleLowerCase().includes("sample")) || `${element.type}`.toLocaleLowerCase().includes("role")) {
                            Object.entries(element.payload).forEach(([keyChange, value]) => {
                                if (`${element.type}`.toLocaleLowerCase().includes("test") || `${element.type}`.toLocaleLowerCase().includes("role")) {
                                    if (!(keyChange == "RequestId")) {
                                        element.message += `<span class="font-weight-semibold">${t(keyChange)}:</span>`;
                                        element.message += ` <span  class="overflow-wrap-anywhere">${value != "" ? value : '" "'}</span><br/>`;
                                    }
                                }
                                else {
                                    try {
                                        let data = JSON.parse(value) || [];
                                        let msgSample = `<br/>`;
                                        data.forEach(sample => {
                                            const keySampleChanges = checkFieldChange(sample.SampleOld, sample.SampleNew)
                                            keySampleChanges.forEach(sampleChange => {
                                                let oldSampleVal = sample.SampleOld[sampleChange]
                                                let newSampleVal = sample.SampleNew[sampleChange]
                                                if (newSampleVal == "1970-01-01T08:00:00" || newSampleVal == "1970-01-01T08:00:00Z") {
                                                    newSampleVal = ""
                                                }
                                                if (oldSampleVal == "1970-01-01T08:00:00" || oldSampleVal == "1970-01-01T08:00:00Z") {
                                                    oldSampleVal = ""
                                                }
                                                if (!(newSampleVal == "" && oldSampleVal == "")) {
                                                    msgSample += `<span class="font-weight-semibold history-text-before-sample">${t(sampleChange)}:</span><span class="history-text-before">  ${(oldSampleVal && oldSampleVal != "") ? oldSampleVal : '" "'}</span><span class="history-text-arrow"></span>`;
                                                    msgSample += ` ${newSampleVal != "" ? newSampleVal : '" "'}<br/>`;
                                                }
                                            });
                                        });
                                        element.message += `<span class="font-weight-semibold">${t(keyChange)}:</span>`;
                                        element.message += `<span  class="overflow-wrap-anywhere">${msgSample}</span>`;
                                    } catch (error) {
                                        if (!(keyChange == "RequestId")) {
                                            element.message += `<span class="font-weight-semibold">${t(keyChange)}:</span>`;
                                            element.message += ` ${value != "" ? value : '" "'}<br/>`;
                                        }
                                    }
                                }
                            });
                        }
                        else {
                            let fin = res.filter(x => moment(x.timeStamp) < moment(element.timeStamp) && checkType(x.type, element.type) && x.payload.id === tmp.id)
                            if (fin && fin.length > 0) {
                                const keyChanges = checkFieldChange(fin[0].payload, element.payload)
                                keyChanges.forEach(keyChange => {
                                    let oldVal = fin[0].payload[keyChange]
                                    element.message += `<span class="font-weight-semibold">${t(keyChange)}</span> <span class="history-text-before">  ${oldVal != "" ? oldVal : '" "'}</span><span class="history-text-arrow"></span>`;
                                    element.message += ` ${tmp[keyChange] != "" ? tmp[keyChange] : '" "'}<br/>`;
                                });

                            }
                            else {
                                const keyChange = firstKeyChange(element.payload)
                                element.message += `<span class="font-weight-semibold">${t(keyChange)}</span> <span class="history-text-before">${t('none')}</span><span class="history-text-arrow"></span>`;
                                element.message += ` ${tmp[keyChange] != "" ? tmp[keyChange] : '" "'}`
                            }
                        }

                    } catch (error) {
                        console.log(error);
                    }
                }
                else if (AuditLogAction.Delete == element.action || AuditLogAction.Deleted == element.action) {
                    if (element.payload?.TestCode) {
                        element.message = `
                        <span class="font-weight-semibold">${t(element.action)}</span>
                        <span class="font-weight-semibold"></span>: ${element.payload?.TestCode}<br/>`
                    }
                }
                else if (AuditLogAction.RunRule == element.action || AuditLogAction.RunRule == element.action) {
                    element.message = `
                    <span class="font-weight-semibold">${t("Test Change")}</span>
                    <span class="font-weight-semibold"></span>:<br/>`
                    try {
                        let data = JSON.parse(element.payload?.TestChange || '[]')
                        data.forEach(row => {
                            element.message += `<span class="font-weight-semibold">${row.TestCode}:</span>`;
                            element.message += `<span  class="overflow-wrap-anywhere">${t("Result")}: ${row.Result || '""'}, ${t("ResultText")}: ${row.ResultText || '""'}</span>`;
                        });

                    } catch (error) {
                    }
                }
                else if (AuditLogAction.ReRun == element.action || AuditLogAction.Confirm == element.action
                    || AuditLogAction.UnConfirm == element.action || AuditLogAction.Validate == element.action
                    || AuditLogAction.InValidate == element.action || AuditLogAction.Released == element.action
                ) {
                    if (element.payload?.TestCode) {
                        element.message = `
                        <span class="font-weight-semibold">${t(element.action)}</span>
                        <span class="font-weight-semibold"></span>: ${element.payload?.TestCode}<br/>`
                    }
                    else {
                        element.message = `
                        <span class="font-weight-semibold">${t("State Result Change")}</span>
                        <span class="font-weight-semibold"></span>: ${t(RESULT_STATE[element.payload?.State])}<br/>`
                    }

                }
                else if (AuditLogAction.InstrumentResult == element.action) {
                    if (element.payload?.TestCode) {
                        element.message = `
                        <span class="font-weight-semibold">${t("TestCode")}</span>
                        <span class="font-weight-semibold"></span>: ${element.payload?.TestCode}<br/>
                        <span class="font-weight-semibold">${t("Result")}</span>
                        <span class="font-weight-semibold"></span>: ${element.payload?.Result || element.payload?.ResultText || ''}<br/>
                        <span class="font-weight-semibold">${t("Machine Name")}</span>
                        <span class="font-weight-semibold"></span>: ${element.payload?.MachineName}<br/>`
                    }

                }
                else if (AuditLogAction.Print == element.action || AuditLogAction.Preview == element.action
                    || AuditLogAction.Export == element.action || AuditLogAction.SendResult == element.action
                ) {
                    element.message = `
                    <span class="font-weight-semibold">${t(element.action)}</span>
                    <span class="font-weight-semibold"></span>: ${element.payload?.TestCode}<br/>`

                }
                else {
                }
            }
            //trả về data ban đầu
            res = res.map(row => {
                try {
                    row.payload = JSON.stringify(row.payload)
                } catch (error) {

                }
                if (currentUser != row.userId) {
                    let tmp = res.find(x => x.userId == row.userId)
                    if (tmp && tmp.color) {
                        row.color = tmp.color
                        row.colorOpposite = tmp.colorOpposite
                    }
                    else {
                        row.color = randomColor()
                        row.colorOpposite = getOppositeColor(row.color)
                    }
                }
                return row
            })
            setDataSource(res)
        }

        useEffect(() => {
            refresh(identifier)
        }, [identifier])

        const refresh = () => {
            onRefresh(identifier)
        }
        useImperativeHandle(ref, () => ({
            onRefresh: () => {
                refresh()
            },
        }));
        const toggle = () => {
            setModal(prev => !prev)
        }
        const onRowClick = (e, data) => {
            setDataPayload(data)
            toggle()
        }
        return (
            <React.Fragment>
                <CustomResultAuditLogTable
                    data={dataSource}
                    onRowClick={onRowClick}
                    module={module}
                />
                <CustomModalLog
                    modal={modal}
                    toggle={toggle}
                    data={dataPayload}
                />
            </React.Fragment>

        )
    })
CustomResultHistory.propTypes = {
    onRefresh: PropTypes.func,
    t: PropTypes.any,
    loadingResultHistory: PropTypes.bool,
    resultHistoryTime: PropTypes.any,
    testResultHistory: PropTypes.array,
    identifier: PropTypes.string,
    resource: PropTypes.string,
    title: PropTypes.string,
}

CustomResultHistory.defaultProps = {}

const mapStateToProps = ({ testResult }) => ({
    loadingResultHistory: testResult.loadingResultAuditlog,
    resultHistoryTime: testResult.resultAuditlogTime,
    testResultHistory: testResult.testResult.resultAuditLog || [],
})

const mapDispatchToProps = dispatch => ({
    onRefresh: payload => dispatch(getTestResultResultAuditLog(payload)),
})

CustomResultHistory.displayName = 'CustomResultHistory';
export default withTranslation(["testResultPage", "message", "common"], { withRef: true })(connect(
    mapStateToProps,
    mapDispatchToProps, null, { forwardRef: true }
)(CustomResultHistory))