import { ModuleIds } from "constant";
import { getAuthorizeMenu, getAuthorizePageByResourceName } from "helpers/app-backend";
import { connect } from "react-redux"
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
const LinkCustom = ({ resource, content, link, menus, userPermissions }) => {
    const [isVisibleResource, setIsVisibleResource] = useState(false);
    // let userInfo = JSON.parse(localStorage.getItem("userInfo"));

    useEffect(() => {
        // let userRole = userInfo?.selectedRole;
        checkVisibleResourceByUserRole();
    }, []);
    const checkVisibleResourceByUserRole = async () => {
        let existsResource = menus.some(obj => obj.pageCode === `_${resource}`);
        if (existsResource) {
            setIsVisibleResource(true);
        }
    };

    return (
        <div>
            {isVisibleResource ? (
                <Link to={link} target="_blank" >{content}</Link>
            ) : (
                <span>{content}</span>
            )}
        </div>
    );
};
const mapStateToProps = ({ Authorization }) => ({
    menus: Authorization.menus,
    userPermissions: Authorization.permissions,
})

export default connect(mapStateToProps)(LinkCustom)