import { AvField } from "availity-reactstrap-validation"
import classnames from "classnames"
import PropTypes from "prop-types"
import { useCallback, useEffect, useRef, useState } from "react"
import { withTranslation } from "react-i18next"
import { withRouter } from "react-router-dom"
import Select, { components } from "react-select"

import {
  getAllCompanies,
  getAllDepartments,
  getAllIndividuals,
  getAllOrganizations,
  getAllParameters,
  getAllParties,
  getAllPatientsByPatientId,
  getAllPatientsByPatientName,
  getAllPatientsByPatientPhone,
  getAllPatientsByPatientPin,
  getAllPhysicians,
  getAllTestProfiles,
  getAllTests,
  getAllUsers,
  getCodesByParameterId
} from "helpers/app-backend"

import { useDetectedChanges } from "helpers/hooks"
import { formatPin, getI18nextLng, isEmptyArray, SelectPopupRatio125 } from "helpers/utilities"

import { parameterCode, PROFILE_TYPE, RequestPatientCondition } from "constant/utility"
import { debounce, isEmpty } from "lodash"
import { Label } from "reactstrap"
import { RollbackButton } from "."

import { getAllMachine } from "helpers/app-backend/machines_backend_helper"
import NumberFormat from "react-number-format"

const CustomAutoComplete = ({
  value,
  name,
  required,
  errorMessage,
  code,
  group,
  onChange,
  isDependent,
  isMulti,
  portal,
  label,
  detected,
  options,
  readOnly,
  searchQuery,
  showDefaultAll,
  isDebounce,
  iconSearch,
  resetTimeout,
  pinNumber,
  isClearable = true,
  autoFocus = false,
  isInsurance = false,
  t,
  emptySelect,
  placeholder
}) => {
  const [pinFocus, setPinFocus] = useState(false)
  const [items, setItems] = useState([])
  const [defaultValue, setDefaultValue] = useState([])
  const [inputValue, setInputValue] = useState("")
  const [newValue, setNewValue] = useState({
    label: "",
    value: "",
  })
  const inputRef = useRef()
  const [valueInput, isChanged] = useDetectedChanges(
    value,
    isMulti ? defaultValue : defaultValue?.[0]
  )

  const DropdownIndicator = props => {
    return (
      iconSearch && (
        <components.DropdownIndicator {...props}>
          <i
            style={{ color: "#495057", width: 20, textAlign: "center" }}
            className="fas fa-search"
          ></i>
        </components.DropdownIndicator>
      )
    )
  }
  const onUndoHandler = () => {
    onChangeHandler(
      isMulti ? (isEmpty(valueInput) ? [] : valueInput) : { value: valueInput }
    )
  }

  const handleKeyDown = event => {
    let key = event.key

    switch (key) {
      case "Enter": {
        const element = document.getElementsByName(name)[0]
        var event = new Event("change", { bubbles: true })
        const value = [inputValue]
        element.value = value || ""
        element.dispatchEvent(event)
        setDefaultValue(value || "")
        onChange(name, value, items?.filter(x => `${x.value}` == `${value}`))
        return
      }
      case "Tab": {
        const element = document.getElementsByName(name)[0]
        //const element = document.getElementsByName(name)[0]
        var event = new Event("change", { bubbles: true })
        const value = [inputValue]
        element.value = value || ""
        element.dispatchEvent(event)
        setDefaultValue(value || "")
        onChange(name, value, items?.filter(x => `${x.value}` == `${value}`))
        //setDefaultValue(inputValue || "")
        //onChange(name, inputValue)

        //setDefaultValue(inputValue || "")
        //setCurrentTagsVal(valueString)
        //onChangeHandler({ value: valueString })
        //setInputValue("")

        return
      }
    }
  }

  const onInputChangeHandler = debounce(val => {
    if (val != "") {
      if (code === parameterCode.ORGANIZATIONS_CODE_SEND) {
        fetchOptions(code, group, value, val)
      }
      else {
        fetchOptions(code, group, value, val)
      }
    }
  }, 600)

  const handleOnInputChange = event => {
    if (event != "") {
      let tempItems = items
      let isExist = tempItems.filter(x => x.value === event)
      if (isExist) {
        tempItems.splice(
          tempItems.findIndex(x => x.label === newValue.value),
          1
        )
        setNewValue({
          ...newValue,
          label: event,
          value: event,
        })
        tempItems.push({
          label: event,
          value: event,
        })
      }
      setInputValue(event)
    }
  }

  const isTouched = inputRef.current?.FormCtrl.isTouched()
  const isDirty = inputRef.current?.FormCtrl.isDirty()
  const isInvalid =
    !readOnly &&
    required &&
    isEmptyArray(defaultValue) &&
    (isTouched || isDirty)

  let lang = getI18nextLng()

  const fetchMachines = async (keySearch, group) => {
    if (keySearch === value)
      keySearch = ''

    const query = {
      protocolId: group,
      search: keySearch,
      size: 15,
      status: 1
    }
    const res = await getAllMachine(query)
    const result = []
    if (res.data) {
      res.data.map(_prof =>
        result.push(
          {
            label: `${_prof.name}`,
            value: _prof.name,
            instrumentId: `${_prof.insID}`,
            machineCode: `${_prof.manageCode}`,
          })
      )
    }
    let isExist = res.data.find(x => x.name === value)
    if (!isExist) {
      result.push({
        value: value,
        label: value,
      })
    }
    return result
  }

  // COMMON CODES
  const fetchCodes = async (code, group, searchQuery) => {
    const query = { lang, group, ...searchQuery }
    const res = await getCodesByParameterId(code, query)
    if (code === parameterCode.TEST_TYPES) {
      res?.map(_item => {
        _item.value = +_item.code
        _item.label = _item.message
        return _item
      })
    } else {
      res?.map(_item => {
        _item.value = _item.code
        _item.label = isInsurance ? _item.code : _item.message
        return _item
      })
      let isExist = res.find(x => x.code == value)
      if (!isExist && !emptySelect) {
        res.push({
          value: value,
          label: value,
        })
      }
    }
    if (code === parameterCode.SAMPLE_TYPES && showDefaultAll) {
      res.unshift({
        value: "--0--",
        label: "All",
      })
    }
    return res
  }

  // PARAMETERS
  const fetchParameters = async () => {
    const query = { size: 100 }
    const res = await getAllParameters(query)
    let data = []
    if (res.data)
      data = res.data.map(_item => {
        _item.value = _item.parameterKey
        _item.label = _item.name
        return _item
      })

    return data
  }

  // DEPARTMENTS
  const fetchDepartments = async () => {
    const query = { size: 100 }
    const res = await getAllDepartments(query)
    let data = []
    if (res.data)
      data = res.data.map(_item => {
        _item.value = _item.id
        _item.label =
          lang === "vi" ? _item.name : _item.englishName || _item.name
        return _item
      })

    return data
  }

  // DEPARTMENTS
  const fetchPhysicians = async () => {
    const query = { size: 100 }
    const res = await getAllPhysicians(query)
    let data = []
    if (res.data)
      data = res.data.map(_item => {
        _item.value = _item.id
        _item.label =
          lang === "vi" ? _item.name : _item.englishName || _item.name
        return _item
      })

    return data
  }

  // INDIVIDUALS
  const fetchIndividuals = async () => {
    const query = { size: 100 }
    const res = await getAllIndividuals(query)
    let data = []
    if (res.data)
      data = res.data.map(_item => {
        _item.value = _item.id
        _item.label =
          lang === "vi" ? _item.name : _item.englishName || _item.name
        return _item
      })

    return data
  }

  // PARTIES
  const fetchParties = async profileId => {
    const query = { size: 100, profileId }
    const res = await getAllParties(query)
    let data = []
    if (res.data)
      data = res.data.map(_item => {
        _item.value = _item.id
        _item.label = _item.name
        return _item
      })

    return data
  }

  const fetchTestProfileCode = async searchQuery => {
    const query = { size: 100, ...searchQuery }
    const res = await getAllTestProfiles(query)
    let data = []
    if (res.data)
      data = res.data.map(_item => {
        _item.value = _item.code
        _item.label = _item.profileName
        return _item
      })

    return data
  }

  const fetchAllTests = async (keySearch, group) => {
    const query = {
      size: 5,
      search: keySearch,
      inUse: true,
      sampleType: group,
    }
    const res = await getAllTests(query)
    let data = []
    if (res.data)
      data = res.data.map(_item => {
        _item.value = _item.id
        _item.label = `${_item.testCode} - ${_item.testName}`
        return _item
      })
    return data
  }

  // USERS
  const fetchUsers = async () => {
    const query = { size: 0 }
    const res = await getAllUsers(query)
    let data = []
    if (res.data)
      data = res.data.map(_item => {
        _item.value = _item.id
        _item.label = _item.familyName + " " + _item.givenName
        return _item
      })

    return data
  }

  const fetchPatient = async (query) => {
    const res = await getAllPatientsByPatientId(query)
    let data = []
    if (res.data)
      data = res.data.map(_item => {
        _item.value = _item.id
        _item.label = _item
        return _item
      })

    return data
  }

  const fetchPatientName = async (query) => {
    const res = await getAllPatientsByPatientName(query)
    let data = []
    if (res.data)
      data = res.data.map(_item => {
        _item.value = _item.id
        _item.label = _item.patientName
        return _item
      })

    return data
  }

  const fetchPin = async (query) => {
    const res = await getAllPatientsByPatientPin(query)
    let data = []
    if (res.data)
      data = res.data.map(_item => {
        _item.value = _item.id
        _item.label = _item.pin
        return _item
      })

    return data
  }

  const fetchPhone = async (query) => {
    const res = await getAllPatientsByPatientPhone(query)
    let data = []
    if (res.data)
      data = res.data.map(_item => {
        _item.value = _item.id
        _item.label = _item.phone
        return _item
      })

    return data
  }

  const fetchPatientsByCondition = async (keySearch, type, valInput = "") => {
    const query = {
      search: valInput != '' ? valInput : (keySearch || ''),
      profileId: PROFILE_TYPE.PATIENT,
      size: 5
    }
    let res = null;
    if (type == RequestPatientCondition.PatientId) {
      res = await getAllPatientsByPatientId(query)
    } else if (type == RequestPatientCondition.PatientName) {
      res = await getAllPatientsByPatientName(query)
    } else if (type == RequestPatientCondition.Phone) {
      res = await getAllPatientsByPatientPhone(query)
    } else if (type == RequestPatientCondition.PIN) {
      res = await getAllPatientsByPatientPin(query)
    }
    let data = []
    if (res.data) {
      let arrDepartment = [];
      res.data.map(x => {
        if (x.furtherValue && x.furtherValue != "") {
          const tmp = JSON.parse(x.furtherValue);
          const index = tmp.findIndex(x => x.FieldCode == "Department")
          if (index >= 0) {
            arrDepartment.push(tmp[index]['FurtherValue']);
          }
        }
      });
      let resDepartment = [];
      arrDepartment = arrDepartment.filter(x => !isEmpty(x))
      if (arrDepartment.length > 0) {
        const deptValues = await getAllDepartments({ id: arrDepartment })
        resDepartment = deptValues.data || []
      }
      //company
      const arrCompanyId = res.data.map(x => x.managementCompanyId) || [];
      const arrCompanyIdDistinct = arrCompanyId.filter((x, index) => !arrCompanyId.includes(x, index + 1)) || []
      let resCompany = [];
      if (arrCompanyIdDistinct.length > 0)
        resCompany = await getAllCompanies({ id: arrCompanyIdDistinct })
      data = res.data.map(_item => {
        let name = `${lang === "vi" ? _item.name : _item.englishName || _item.name}`;
        //department
        if (resDepartment.length > 0) {
          const further = JSON.parse(_item.furtherValue)?.find(x => x.FieldCode == 'Department')
          if (further) _item.departmentName = resDepartment?.find(x => `${x.id}` == `${further.FurtherValue}`)?.name
        }
        //company
        _item.managementCompanyId = resCompany.data?.find(x => x.id == _item.managementCompanyId)?.shortName || ''
        if (_item.dobString) {
          name = `${name}`
        }
        let arr = []
        _item.value = _item.patientId
        if (_item.fields && _item.fields != '[{}]' && _item.fields != '') {
          arr = SortFieldIndividuals(_item)
        }
        arr.unshift(name);
        let label = arr.filter(Boolean).join(" • ")
        if (type == RequestPatientCondition.PatientId) {
          _item.label = arr[4].trim()
        } else if (type == RequestPatientCondition.PatientName) {
          _item.label = arr[0]
        } else if (type == RequestPatientCondition.Phone) {
          _item.label = arr[8]
        } else if (type == RequestPatientCondition.PIN) {
          _item.label = arr[1]
        }
        else {
          _item.label = `${label}`
        }
        return _item
      })
      if (type == RequestPatientCondition.PIN) {
        data = data.filter(_item => _item.label)
      }
    }
    if (data.findIndex(x => x.value == value) < 0 && value != '' && value != undefined) {
      data.push({
        label: value,
        value
      })
    }
    if (valInput != '') {
      let isExist = data.filter(x => x.value === valInput) || []
      if (isExist.length == 0) {
        data.push({
          label: valInput,
          value: valInput,
        })
      }
    }
    return data
  }

  const SortFieldIndividuals = item => {
    let arr = []
    let _item = item
    const furtherValue = JSON.parse(_item.furtherValue) || []
    const pinValue = furtherValue.find(item => item.FieldCode === "Identifier")?.FurtherValue || ""
    arr.unshift(`${pinValue}`)
    let fields = JSON.parse(_item.fields) || []
    fields.forEach(eField => {
      let fieldCode = eField.FieldCode
      let addressFields = []
      if (
        _item.addressFields &&
        _item.addressFields != "[{}]" &&
        _item.addressFields != ""
      ) {
        addressFields = JSON.parse(_item.addressFields)
      }
      let contactFields = []
      if (
        _item.contactFields &&
        _item.contactFields != "[{}]" &&
        _item.contactFields != ""
      ) {
        contactFields = JSON.parse(_item.contactFields)
      }
      if (fieldCode.includes("Contact.")) {
        if (contactFields.length > 0) {
          const field = fieldCode.split(".")[1]
          if (field?.toUpperCase() == "CONTACTROLE") {
            arr.push(contactFields[0]["ContactRole"])
          } else {
            arr.push(contactFields[0][field])
          }
        }
      } else if (fieldCode.includes("Address.")) {
        if (addressFields.length > 0) {
          const field = fieldCode.split(".")[1]
          if (field?.toUpperCase() == "TYPE") {
            arr.push(addressFields[0]["TypeName"])
          } else if (field?.toUpperCase() == "Address") {
            arr.push(_item.fullAddress)
          } else {
            arr.push(addressFields[0][field])
          }
        }
      }
      else if (fieldCode?.toUpperCase() === "PIN") {
        const pinValue = furtherValue.find(item => item.FieldCode === "PIN")?.FurtherValue || ""
        arr.push(`${pinValue}`)
      }
      else {
        if (fieldCode?.toUpperCase() === "dob".toUpperCase()) {
          // arr.push(`<strong>${_item.dobName}</strong>`)
          arr.push(`${_item.dobName}`)
        } else {
          fieldCode = fieldCode.charAt(0).toLowerCase() + fieldCode.slice(1)
          if (_item.hasOwnProperty(fieldCode + "Name")) {
            arr.push(_item[fieldCode + "Name"])
          } else {
            arr.push(_item[fieldCode])
          }
        }
      }
    })
    return arr
  }

  const fetchOrganizationsByCodeSend = async (value, group, valInput) => {
    let stringSearch = valInput || value
    let query = { profileId: 5, size: 0, search: stringSearch, managementCompanyId: group }
    const res = await getAllOrganizations(query)
    let data = []
    if (res.data)
      data = res.data.map(_item => {
        _item.value = _item.organizationCode
        _item.label = _item.name
        return _item
      })
    if (valInput && valInput != null) {
      if (data.findIndex(x => `${x.value}` === `${valInput}`) < 0) {
        data.unshift({
          value: valInput,
          label: valInput,
          vendorCode: valInput
        })
      }
    }
    return data
  }

  const fetchOptions = useCallback(
    async (code, group, searchQuery, valInput = "") => {
      let res = []
      if (code === parameterCode.PARAMETER) {
        res = await fetchParameters()
      } else if (code === parameterCode.INDIVIDUALS) {
        res = await fetchIndividuals()
      } else if (code === parameterCode.ORGANIZATIONS_CODE_SEND) {
        res = await fetchOrganizationsByCodeSend(value, group, valInput)
      } else if (code === parameterCode.DEPARTMENTS) {
        res = await fetchDepartments()
      } else if (code === parameterCode.PHYSICIANS) {
        res = await fetchPhysicians()
      } else if (code === parameterCode.PATIENTS_BY_CONDITION) {
        // if (group === RequestPatientCondition.PIN) {
        //   res = await fetchPin(query)
        // }
        // else if (group === RequestPatientCondition.PatientId) {
        //   res = await fetchPatient(query)
        // }
        // else if (group === RequestPatientCondition.PatientName) {
        //   res = await fetchPatientName(query)
        // }
        // else if (group === RequestPatientCondition.Phone) {
        //   res = await fetchPhone(query)
        // } else {
        res = await fetchPatientsByCondition(value, group, valInput)
        // }
      } else if (code === parameterCode.TESTPROFILE_TEST) {
        res = await fetchAllTests(value, group)
      } else if (code === parameterCode.TESTPROFILE_CODE) {
        res = await fetchTestProfileCode(searchQuery)
      } else if (code === parameterCode.INSTRUMENT) {
        res = await fetchMachines(value, group)
      } else if (code === parameterCode.USERS) {
        res = await fetchUsers()
      }
      else if (code.indexOf("_") >= 0 || code === parameterCode.PROFILE) {
        const profileId = code.substring(code.indexOf("_") + 1)
        if (profileId > 0) res = await fetchParties(profileId)
        else res = await fetchParties()
      } else {
        res = await fetchCodes(code, group, searchQuery)
      }
      setItems(res)
    },
    []
  )

  const onChangeHandler = (e, b) => {
    const element = document.getElementsByName(name)[0]
    var event = new Event("change", { bubbles: true })
    const value =
      (isMulti
        ? Array.isArray(e)
          ? e?.map(_value => {
            return _value.value
          })
          : e?.split(",")
        : [e?.value]) || []
    element.value = value || ""
    element.dispatchEvent(event)
    setDefaultValue(value || "")
    onChange(name, value, items?.filter(x => `${x.value}` == `${value}`))
  }

  const isValidStyle = isInvalid && {
    borderColor: "#f46a6a !important",
    backgroundImage: `url(
    "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23f46a6a'><circle cx='6' cy='6' r='4.5'/><path stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/><circle cx='6' cy='8.2' r='.6' fill='%23f46a6a' stroke='none'/></svg>"
  )`,
    backgroundPosition: "right 2.75rem center, center right 0.5rem",
    backgroundSize: "17px 18px, calc(0.75em + 0.47rem) calc(0.75em + 0.47rem)",
    backgroundRepeat: "no-repeat",
  }

  const colourStyles = {
    control: (styles, { data, isDisabled, isFocused, isSelected }) => ({
      ...styles,
      backgroundColor: isDisabled ? "#edf1f2 !important" : "white",
      fontSize: "13px",
      cursor: isDisabled ? "not-allowed" : "default",
      ...isValidStyle,
      // ...isVaild,
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      // const color = chroma(data.color);
      return {
        ...styles,
        fontSize: "13px",
        // color: "black",
        // backgroundColor: isDisabled ? null : isSelected ? data.color : null,
        cursor: isDisabled ? "not-allowed" : "default",
      }
    },
    singleValue: (provided, state) => {
      const opacity =
        state.isDisabled || !state.data.value || state.data.value === "0"
          ? 0.7
          : 1
      const transition = "opacity 300ms"

      return { ...provided, opacity, transition }
    },

    menuPortal: provided => ({
      ...provided,
      zIndex: 9999,
    }),
    menu: provided => ({ ...provided, zIndex: 9999 }),
  }

  useEffect(() => {
    if (code && (!isDependent || (isDependent && group)))
      fetchOptions(code, group, searchQuery)
    else setItems([])
  }, [code, group, isDependent, searchQuery])

  useEffect(() => {
    setDefaultValue(isMulti ? value : [value])
  }, [value])

  useEffect(() => {
    if (!isEmpty(options)) setItems(options)
  }, [options])

  useEffect(() => {
    if (value == "" && code == parameterCode.PATIENTS_BY_CONDITION) {
      cleanOldData()
    }
  }, [value])

  const cleanOldData = async () => {
    let res = []
    res = await fetchPatientsByCondition(value, group, '')
    setItems(res)
  }
  return (
    <>
      <div className="label-group-relative position-relative">
        {label && (
          <Label for={name}>
            {label}
            {required && <span className="text-danger">*</span>}
          </Label>
        )}

        <RollbackButton
          display={isChanged && detected}
          onClick={onUndoHandler}
        />
      </div>
      <Select
        onMenuOpen={() => {
          SelectPopupRatio125('.select2-selection__menu-portal')
        }}
        components={
          pinNumber
            ? {
              Input: inputProps => (
                <NumberFormat
                  format={formatPin}
                  name="pin"
                  {...inputProps}
                  style={{ border: "none", width: "100%" }}
                  autoFocus={pinFocus}
                  onFocus={() => {
                    setPinFocus(true)
                  }}
                />
              ),
              DropdownIndicator,
            }
            : {
              DropdownIndicator,
            }
        }
        autoFocus={autoFocus}
        id={"select-customautocomplete-" + name}
        name="select-customautocomplete"
        openMenuOnFocus
        isClearable={isClearable}
        menuPlacement="auto"
        maxMenuHeight={150}
        menuPortalTarget={!portal && document.body}
        styles={colourStyles}
        isDisabled={readOnly}
        isMulti={isMulti}
        value={items.filter(
          _item =>
            defaultValue?.findIndex(
              _defaultValue =>
                JSON.stringify(_defaultValue + "") ===
                JSON.stringify(_item.value + "")
            ) >= 0
        )}
        onChange={onChangeHandler}
        options={items}
        formatOptionLabel={function (data) {
          return (
            <span dangerouslySetInnerHTML={{ __html: data.label }} />
          );
        }}
        classNamePrefix="select2-selection"
        className={classnames(
          { "has-changed": isChanged && detected },
          "form-select2 is-touched is-dirty av-invalid is-invalid"
        )}
        onKeyDown={handleKeyDown}
        onInputChange={(e) => {
          resetTimeout && resetTimeout()
          if (isDebounce == true)
            onInputChangeHandler(e)
          else
            handleOnInputChange(e)
        }}
        placeholder={placeholder ? t(placeholder) : t("Select Custom")}
      />
      {isInvalid && (
        <div className="text-danger form-group">
          <div className="is-touched is-dirty av-invalid is-invalid"></div>
          <div className="invalid-feedback">{errorMessage}</div>
        </div>
      )}
      <div className="d-none">
        <AvField
          multiple={isMulti}
          name={name}
          type="select"
          className={`form-select`}
          value={isMulti ? defaultValue : defaultValue?.[0]}
          errorMessage={errorMessage}
          required={!readOnly && required}
          ref={inputRef}
          readOnly={readOnly}
        >
          <option value="">--Select--</option>
          {items.map((_item, idx) => (
            <option key={idx} value={_item.value}>
              {_item.label}
            </option>
          ))}
        </AvField>
      </div>
    </>
  )
}

CustomAutoComplete.propTypes = {
  onChange: PropTypes.func,
  name: PropTypes.string.isRequired,
  isDependent: PropTypes.bool,
  required: PropTypes.bool,
  errorMessage: PropTypes.string,
  value: PropTypes.any,
  group: PropTypes.string,
  code: PropTypes.string,
  isMulti: PropTypes.bool,
  portal: PropTypes.bool,
  label: PropTypes.any.isRequired,
  detected: PropTypes.bool,
  options: PropTypes.array,
  readOnly: PropTypes.bool,
  searchQuery: PropTypes.object,
  showDefaultAll: PropTypes.bool,
  isClearable: PropTypes.bool,
}

CustomAutoComplete.defaultProps = {
  name: "",
  onChange: () => { },
  isDependent: false,
  errorMessage: "This field is invalid",
  portal: false,
  searchQuery: {},
}

export default (withRouter(withTranslation(["common"])(CustomAutoComplete)))
