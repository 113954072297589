import { AvField } from "availity-reactstrap-validation"
import { CustomSelect, DatePicker } from "components/Common"
import { parameterCode } from "constant/utility"
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import { Label, Row } from "reactstrap"

const FilterForm = ({ model, t }) => {
    return (
        <>
            <Row>
                <div className="mb-3">
                    <DatePicker
                        name="requestDate"
                        label={t("Request Date")}
                        value={model.requestDate || new Date()}
                        placeholder={"YYYY-MM-DD"}
                        format={"YYYY-MM-DD"}
                        onChange={(e) => {
                            const formattedDate = e.value.toISOString().split('T')[0];
                            model.requestDate = formattedDate
                        }}
                    />
                </div>
            </Row>
            <Row>
                <div className="mb-3">
                    <CustomSelect
                        label={t("Test Code")}
                        portal
                        name="testCode"
                        value={model.testCode || ""}
                        code={parameterCode.TESTPROFILE_TEST}
                        onChange={(name, value, label, item) => {
                            model.testCode = value[0];
                        }}
                    />
                </div>
            </Row>
            <Row>
                <div className="mb-3">
                    <CustomSelect
                        label={t("qcResultPage:Instrument")}
                        portal
                        showID
                        name="instrument"
                        value={model.instrument || ""}
                        code={parameterCode.INSTRUMENT}
                        onChange={(name, value, label, item) => {
                            model.instrument = value[0];
                        }}
                    />
                </div>
            </Row>
        </>
    )
}

FilterForm.propTypes = {
    model: PropTypes.object,
    t: PropTypes.any,
}

export default withTranslation(["qcResultPage", "common"])(FilterForm)
