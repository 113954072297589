import {
    CONFIRM_TESTRESULT_RESULTS, CONFIRM_TESTRESULT_RESULTS_FAIL, CONFIRM_TESTRESULT_RESULTS_SUCCESS,
    DELETE_RESULTS, DELETE_RESULTS_FAIL, DELETE_RESULTS_SUCCESS, DELETE_TESTRESULT_RESULTS, DELETE_TESTRESULT_RESULTS_FAIL, DELETE_TESTRESULT_RESULTS_SUCCESS,
    GET_RESULTS, GET_RESULTS_FAIL, GET_RESULTS_SUCCESS, GET_TESTRESULT_COMMENT, GET_TESTRESULT_COMMENT_FAIL, GET_TESTRESULT_COMMENT_SUCCESS,
    GET_TESTRESULT_DETAIL, GET_TESTRESULT_DETAIL_FAIL, GET_TESTRESULT_DETAIL_SUCCESS, GET_TESTRESULT_HISTORY, GET_TESTRESULT_HISTORY_FAIL,
    GET_TESTRESULT_HISTORY_SUCCESS, GET_TESTRESULT_PROFILE_DETAIL, GET_TESTRESULT_PROFILE_DETAIL_FAIL, GET_TESTRESULT_PROFILE_DETAIL_SUCCESS,
    GET_TESTRESULT_RESULTS, GET_TESTRESULT_RESULTS_FAIL, GET_TESTRESULT_RESULTS_SUCCESS, GET_TESTRESULT_TESTS_LOADING_COMPLETE, GET_TESTRESULT_TEST_DETAIL,
    GET_TESTRESULT_TEST_DETAIL_FAIL, GET_TESTRESULT_TEST_DETAIL_SUCCESS, INVALID_TESTRESULT_RESULTS, INVALID_TESTRESULT_RESULTS_FAIL,
    INVALID_TESTRESULT_RESULTS_SUCCESS, RESET_TESTRESULTS_SEARCH_QUERY, SET_TESTRESULTS_SEARCH_QUERY, UNCONFIRM_TESTRESULT_RESULTS,
    UNCONFIRM_TESTRESULT_RESULTS_FAIL, UNCONFIRM_TESTRESULT_RESULTS_SUCCESS, UPDATE_TESTRESULTS, UPDATE_TESTRESULTS_FAIL, UPDATE_TESTRESULTS_SUCCESS,
    UPDATE_TESTRESULT_COMMENT, UPDATE_TESTRESULT_COMMENT_FAIL, UPDATE_TESTRESULT_COMMENT_SUCCESS, UPDATE_TESTRESULT_RESULTS, UPDATE_TESTRESULT_RESULTS_FAIL,
    UPDATE_TESTRESULT_RESULTS_SUCCESS, VALID_TESTRESULT_RESULTS, VALID_TESTRESULT_RESULTS_FAIL, VALID_TESTRESULT_RESULTS_SUCCESS,

    GET_RESULT_COLUMNS_CURRENT_SETTINGS, GET_RESULT_COLUMNS_CURRENT_SETTINGS_SUCCESS, GET_RESULT_COLUMNS_CURRENT_SETTINGS_FAIL,
    GET_PARAMETER_RESULT_COLUMNS_SETTINGS, GET_PARAMETER_RESULT_COLUMNS_SETTINGS_SUCCESS, GET_PARAMETER_RESULT_COLUMNS_SETTINGS_FAIL,
    UPDATE_RESULT_COLUMNS_SETTINGS, UPDATE_RESULT_COLUMNS_SETTINGS_SUCCESS, UPDATE_RESULT_COLUMNS_SETTINGS_FAIL, GET_TESTPERFORM_RESULTS, GET_TESTPERFORM_RESULTS_SUCCESS, GET_TESTPERFORM_RESULTS_FAIL,
    GET_UPDATABLE_RESULTFIELD, GET_UPDATABLE_RESULTFIELD_SUCCESS, GET_UPDATABLE_RESULTFIELD_FAIL,
    RERUN_TESTRESULT_RESULTS, RERUN_TESTRESULT_RESULTS_SUCCESS, RERUN_TESTRESULT_RESULTS_FAIL, GET_RESULT_COMMENT, GET_RESULT_COMMENT_SUCCESS, GET_RESULT_COMMENT_FAIL,
    GET_CATEGORY_COMMENT, GET_CATEGORY_COMMENT_SUCCESS, GET_CATEGORY_COMMENT_FAIL, UPDATE_RESULT_COMMENT, UPDATE_RESULT_COMMENT_SUCCESS, UPDATE_RESULT_COMMENT_FAIL,
    UPDATE_CATEGORY_COMMENT, UPDATE_CATEGORY_COMMENT_SUCCESS, UPDATE_CATEGORY_COMMENT_FAIL, RUNRULE_TESTRESULT_RESULTS, RUNRULE_TESTRESULT_RESULTS_SUCCESS,
    RUNRULE_TESTRESULT_RESULTS_FAIL, GET_GENERAL_HISTORY_RESULT, GET_GENERAL_HISTORY_RESULT_SUCCESS, GET_GENERAL_HISTORY_RESULT_FAIL, GET_PATIENT_RESULT,
    GET_PATIENT_RESULT_SUCCESS, GET_PATIENT_RESULT_FAIL, SET_ROW_COUNT_SUCCESS, GET_TESTRESULT_RESULT_AUDITLOG, GET_TESTRESULT_RESULT_AUDITLOG_FAIL, GET_TESTRESULT_RESULT_AUDITLOG_SUCCESS, GET_PARAMETER_GROUP_BY_CODE_SUCCESS, GET_RESULT_NOTIFY, GET_RESULT_NOTIFY_SUCCESS, GET_RESULT_NOTIFY_FAIL, CREATE_RESULT_NOTIFY, CREATE_RESULT_NOTIFY_SUCCESS, CREATE_RESULT_NOTIFY_FAIL, WARNING_RULE_TESTRESULT_RESULTS, WARNING_RULE_TESTRESULT_RESULTS_SUCCESS, WARNING_RULE_TESTRESULT_RESULTS_FAIL, CLEAR_TESTRESULT_DETAIL, GET_TEST_REQUEST_AUDITLOG, RESET_TESTRESULTS_SEARCH_QUERY_CALLBACK, SET_LOADING_CHANGE_RESULT_DETAIL, SET_SCROLL_RESULT_DETAIL, GET_RESULTS_WITHOUT_LOADING_FAIL, GET_RESULTS_WITHOUT_LOADING_SUCCESS, GET_RESULTS_WITHOUT_LOADING, APPROVED_VALID_AND_PUBLISH_RESULT, APPROVED_VALID_AND_PUBLISH_RESULT_SUCCESS, APPROVED_VALID_AND_PUBLISH_RESULT_FAIL, SET_FAST_VALID, GET_PATHOLOGY_RESULT, GET_PATHOLOGY_RESULT_SUCCESS, GET_PATHOLOGY_RESULT_FAIL, CREATE_PATHOLOGY_RESULT, CREATE_PATHOLOGY_RESULT_SUCCESS, CREATE_PATHOLOGY_RESULT_FAIL, UPDATE_PATHOLOGY_RESULT_SUCCESS, UPDATE_PATHOLOGY_RESULT_FAIL, UPDATE_PATHOLOGY_RESULT, UPDATE_RESULT_TEST_VENDOR, UPDATE_RESULT_TEST_VENDOR_SUCCESS, UPDATE_RESULT_TEST_VENDOR_FAIL, UPDATE_RESULT_PROFILE_VENDOR, UPDATE_RESULT_PROFILE_VENDOR_SUCCESS, UPDATE_RESULT_PROFILE_VENDOR_FAIL,
    SIGN_TESTRESULT_RESULTS,
    SIGN_TESTRESULT_RESULTS_SUCCESS,
    SIGN_TESTRESULT_RESULTS_FAIL,
    UNSIGN_TESTRESULT_RESULTS,
    UNSIGN_TESTRESULT_RESULTS_SUCCESS,
    UNSIGN_TESTRESULT_RESULTS_FAIL,
    USER_VALIDATE,
    CLEAR_USER_VALIDATE,
    GET_SEND_RESULTS,
    GET_SEND_RESULTS_SUCCESS,
    GET_SEND_RESULTS_FAIL,
    GET_SEND_RESULTS_WITHOUT_LOADING,
    GET_SEND_RESULTS_WITHOUT_LOADING_SUCCESS,
    GET_SEND_RESULTS_WITHOUT_LOADING_FAIL,
    RESET_SEND_TESTRESULTS_SEARCH_QUERY,
    SET_SEND_TESTRESULTS_SEARCH_QUERY,
    GET_PATIENT_REQUEST_WAITING,
    GET_HAEMOGRAM_RESULT, GET_HAEMOGRAM_RESULT_SUCCESS, GET_HAEMOGRAM_RESULT_FAIL,
    CREATE_HAEMOGRAM_RESULT, CREATE_HAEMOGRAM_RESULT_SUCCESS, CREATE_HAEMOGRAM_RESULT_FAIL,
    UPDATE_HAEMOGRAM_RESULT, UPDATE_HAEMOGRAM_RESULT_SUCCESS, UPDATE_HAEMOGRAM_RESULT_FAIL,
    SET_CATEGORY_FILTER

} from "./actionTypes";

//reset search query
export const resetSearchQuery = () => ({
    type: RESET_TESTRESULTS_SEARCH_QUERY
})

export const resetSearchQueryCallback = (callback) => ({
    type: RESET_TESTRESULTS_SEARCH_QUERY_CALLBACK,
    callback: callback
})

//set search query
export const setSearchQuery = searchQuery => ({
    type: SET_TESTRESULTS_SEARCH_QUERY,
    payload: searchQuery
})

//get test result
export const getResults = payload => ({
    type: GET_RESULTS,
    payload: payload || {}
})

export const getResultsSuccess = payload => ({
    type: GET_RESULTS_SUCCESS,
    payload: payload
})

export const getResultsFail = error => ({
    type: GET_RESULTS_FAIL,
    payload: error
})

export const getResultsWithoutLoading = (payload, callback) => ({
    type: GET_RESULTS_WITHOUT_LOADING,
    payload: payload || {},
    callback: callback
})

export const getResultsWithoutLoadingSuccess = payload => ({
    type: GET_RESULTS_WITHOUT_LOADING_SUCCESS,
    payload: payload
})

export const getResultsWithoutLoadingFail = error => ({
    type: GET_RESULTS_WITHOUT_LOADING_FAIL,
    payload: error
})

//result detail
export const getTestResultDetail = (id) => ({
    type: GET_TESTRESULT_DETAIL,
    id,
})

export const getTestResultDetailSuccess = testRequests => ({
    type: GET_TESTRESULT_DETAIL_SUCCESS,
    payload: testRequests,
})

export const getTestResultDetailFail = error => ({
    type: GET_TESTRESULT_DETAIL_FAIL,
    payload: error,
})

export const updateTestResult = payload => ({
    type: UPDATE_TESTRESULTS,
    payload: payload,
})

export const updateTestResultSuccess = payload => ({
    type: UPDATE_TESTRESULTS_SUCCESS,
    payload: payload,
})

export const updateTestResultFail = error => ({
    type: UPDATE_TESTRESULTS_FAIL,
    payload: error,
})

//delete
export const deleteResults = payload => ({
    type: DELETE_RESULTS,
    payload: payload,
})

export const deleteResultsSuccess = payload => ({
    type: DELETE_RESULTS_SUCCESS,
    payload: payload,
})

export const deleteResultsFail = error => ({
    type: DELETE_RESULTS_FAIL,
    payload: error,
})

//get history
export const getTestResultHistory = payload => ({
    type: GET_TESTRESULT_HISTORY,
    payload: payload || {},
})
export const getTestResultHistorySuccess = payload => ({
    type: GET_TESTRESULT_HISTORY_SUCCESS,
    payload: payload,
})
export const getTestResultHistoryFail = error => ({
    type: GET_TESTRESULT_HISTORY_FAIL,
    payload: error,
})

//get test comment
export const getTestResultComment = payload => ({
    type: GET_TESTRESULT_COMMENT,
    payload: payload || {},
})
export const getTestResultCommentSuccess = payload => ({
    type: GET_TESTRESULT_COMMENT_SUCCESS,
    payload: payload,
})
export const getTestResultCommentFail = error => ({
    type: GET_TESTRESULT_COMMENT_FAIL,
    payload: error,
})


/**
 * 
 * TEST RESULT START
 * 
 */


export const getTestResultProfileDetail = payload => ({
    type: GET_TESTRESULT_PROFILE_DETAIL,
    payload: payload,
})

export const getTestResultProfileDetailSuccess = payload => ({
    type: GET_TESTRESULT_PROFILE_DETAIL_SUCCESS,
    payload: payload,
})

export const getTestResultProfileDetailFail = error => ({
    type: GET_TESTRESULT_PROFILE_DETAIL_FAIL,
    payload: error
})

export const getTestResultTestDetail = payload => ({
    type: GET_TESTRESULT_TEST_DETAIL,
    payload: payload
})

export const getTestResultTestDetailSuccess = payload => ({
    type: GET_TESTRESULT_TEST_DETAIL_SUCCESS,
    payload: payload
})

export const getTestResultTestDetailFail = error => ({
    type: GET_TESTRESULT_TEST_DETAIL_FAIL,
    payload: error
})

//get comment
export const UpdateTestResultComment = payload => ({
    type: UPDATE_TESTRESULT_COMMENT,
    payload: payload || [],
})
export const UpdateTestResultCommentSuccess = payload => ({
    type: UPDATE_TESTRESULT_COMMENT_SUCCESS,
    payload: payload,
})
export const UpdateTestResultCommentFail = error => ({
    type: UPDATE_TESTRESULT_COMMENT_FAIL,
    payload: error,
})
/**
 * 
 * TEST RESULT END
 * 
 */

export const getTestResultResults = payload => ({
    type: GET_TESTRESULT_RESULTS,
    payload: payload || {},
})
export const getTestResultResultsSuccess = payload => ({
    type: GET_TESTRESULT_RESULTS_SUCCESS,
    payload: payload,
})
export const getTestResultResultsFail = error => ({
    type: GET_TESTRESULT_RESULTS_FAIL,
    payload: error,
})

export const getTestResultTestsLoadingComplete = () => ({
    type: GET_TESTRESULT_TESTS_LOADING_COMPLETE,
})

export const UpdateTestResultResults = payload => ({
    type: UPDATE_TESTRESULT_RESULTS,
    payload: payload || [],
})
export const UpdateTestResultResultsSuccess = payload => ({
    type: UPDATE_TESTRESULT_RESULTS_SUCCESS,
    payload: payload,
})
export const UpdateTestResultResultsFail = error => ({
    type: UPDATE_TESTRESULT_RESULTS_FAIL,
    payload: error,
})

export const ConfirmTestResultResults = testsconfig => ({
    type: CONFIRM_TESTRESULT_RESULTS,
    payload: testsconfig,
})
export const ConfirmTestResultResultsSuccess = testsconfig => ({
    type: CONFIRM_TESTRESULT_RESULTS_SUCCESS,
    payload: testsconfig,
})

export const ConfirmTestResultResultsFail = error => ({
    type: CONFIRM_TESTRESULT_RESULTS_FAIL,
    payload: error,
})

export const UnConfirmTestResultResults = testsconfig => ({
    type: UNCONFIRM_TESTRESULT_RESULTS,
    payload: testsconfig,
})
export const UnConfirmTestResultResultsSuccess = testsconfig => ({
    type: UNCONFIRM_TESTRESULT_RESULTS_SUCCESS,
    payload: testsconfig,
})

export const UnConfirmTestResultResultsFail = error => ({
    type: UNCONFIRM_TESTRESULT_RESULTS_FAIL,
    payload: error,
})

export const ValidTestResultResults = testsconfig => ({
    type: VALID_TESTRESULT_RESULTS,
    payload: testsconfig,
})
export const ValidTestResultResultsSuccess = testsconfig => ({
    type: VALID_TESTRESULT_RESULTS_SUCCESS,
    payload: testsconfig,
})

export const ValidTestResultResultsFail = error => ({
    type: VALID_TESTRESULT_RESULTS_FAIL,
    payload: error,
})

export const ApprovedValidAndPublishResult = file => ({
    type: APPROVED_VALID_AND_PUBLISH_RESULT,
    payload: file,
})
export const ApprovedValidAndPublishResultSuccess = file => ({
    type: APPROVED_VALID_AND_PUBLISH_RESULT_SUCCESS,
    payload: file,
})

export const ApprovedValidAndPublishResultFail = error => ({
    type: APPROVED_VALID_AND_PUBLISH_RESULT_FAIL,
    payload: error,
})

export const InValidTestResultResults = testsconfig => ({
    type: INVALID_TESTRESULT_RESULTS,
    payload: testsconfig,
})
export const InValidTestResultResultsSuccess = testsconfig => ({
    type: INVALID_TESTRESULT_RESULTS_SUCCESS,
    payload: testsconfig,
})

export const InValidTestResultResultsFail = error => ({
    type: INVALID_TESTRESULT_RESULTS_FAIL,
    payload: error,
})

export const DeleteTestResultResults = testsconfig => ({
    type: DELETE_TESTRESULT_RESULTS,
    payload: testsconfig,
})
export const DeleteTestResultResultsSuccess = testsconfig => ({
    type: DELETE_TESTRESULT_RESULTS_SUCCESS,
    payload: testsconfig,
})

export const DeleteTestResultResultsFail = error => ({
    type: DELETE_TESTRESULT_RESULTS_FAIL,
    payload: error,
})

//SETTING COLUMNS
export const updateResultColumnsSettings = payload => ({
    type: UPDATE_RESULT_COLUMNS_SETTINGS,
    payload,
})

export const updateResultColumnsSettingsSuccess = payload => ({
    type: UPDATE_RESULT_COLUMNS_SETTINGS_SUCCESS,
    payload: payload,
})

export const updateResultColumnsSettingsFail = error => ({
    type: UPDATE_RESULT_COLUMNS_SETTINGS_FAIL,
    payload: error,
})

export const getResultColumnsCurrentSettings = payload => ({
    type: GET_RESULT_COLUMNS_CURRENT_SETTINGS,
    payload: payload || {},
})

export const getResultColumnsCurrentSettingsSuccess = payload => ({
    type: GET_RESULT_COLUMNS_CURRENT_SETTINGS_SUCCESS,
    payload: payload,
})
export const getResultColumnsCurrentSettingsFail = error => ({
    type: GET_RESULT_COLUMNS_CURRENT_SETTINGS_FAIL,
    payload: error,
})

export const getParameterResultColumnsSettings = payload => ({
    type: GET_PARAMETER_RESULT_COLUMNS_SETTINGS,
    payload: payload || {},
})

export const getParameterResultColumnsSettingsSuccess = payload => ({
    type: GET_PARAMETER_RESULT_COLUMNS_SETTINGS_FAIL,
    payload: payload,
})

export const getParameterResultColumnsSettingsFail = error => ({
    type: GET_PARAMETER_RESULT_COLUMNS_SETTINGS_SUCCESS,
    payload: error,
})

export const getTestPerformResults = payload => ({
    type: GET_TESTPERFORM_RESULTS,
    payload: payload || {},
})
export const getTestPerformResultsSuccess = payload => ({
    type: GET_TESTPERFORM_RESULTS_SUCCESS,
    payload: payload,
})
export const getTestPerformResultsFail = error => ({
    type: GET_TESTPERFORM_RESULTS_FAIL,
    payload: error,
})

export const getUpdatableResultField = payload => ({
    type: GET_UPDATABLE_RESULTFIELD,
    payload: payload || {},
})

export const getUpdatableResultFieldSuccess = payload => ({
    type: GET_UPDATABLE_RESULTFIELD_SUCCESS,
    payload: payload,
})

export const getUpdatableResultFieldFail = error => ({
    type: GET_UPDATABLE_RESULTFIELD_FAIL,
    payload: error,
})

export const rerunTestResultResults = payload => ({
    type: RERUN_TESTRESULT_RESULTS,
    payload: payload,
})
export const rerunTestResultResultsSuccess = payload => ({
    type: RERUN_TESTRESULT_RESULTS_SUCCESS,
    payload: payload,
})

export const rerunTestResultResultsFail = error => ({
    type: RERUN_TESTRESULT_RESULTS_FAIL,
    payload: error,
})

//get result comment
export const getResultComment = payload => ({
    type: GET_RESULT_COMMENT,
    payload: payload || {},
})
export const getResultCommentSuccess = payload => ({
    type: GET_RESULT_COMMENT_SUCCESS,
    payload: payload,
})
export const getResultCommentFail = error => ({
    type: GET_RESULT_COMMENT_FAIL,
    payload: error,
})

//GET CATEGORY COMMENT
export const getCategoryComment = payload => ({
    type: GET_CATEGORY_COMMENT,
    payload: payload || {},
})
export const getCategoryCommentSuccess = payload => ({
    type: GET_CATEGORY_COMMENT_SUCCESS,
    payload: payload,
})
export const getCategoryCommentFail = error => ({
    type: GET_CATEGORY_COMMENT_FAIL,
    payload: error,
})

//UPDATE result comment
export const updateResultComment = payload => ({
    type: UPDATE_RESULT_COMMENT,
    payload: payload || {},
})
export const updateResultCommentSuccess = payload => ({
    type: UPDATE_RESULT_COMMENT_SUCCESS,
    payload: payload,
})
export const updateResultCommentFail = error => ({
    type: UPDATE_RESULT_COMMENT_FAIL,
    payload: error,
})

//UPDATE CATEGORY COMMENT
export const updateCategoryComment = payload => ({
    type: UPDATE_CATEGORY_COMMENT,
    payload: payload || {},
})
export const updateCategoryCommentSuccess = payload => ({
    type: UPDATE_CATEGORY_COMMENT_SUCCESS,
    payload: payload,
})
export const updateCategoryCommentFail = error => ({
    type: UPDATE_CATEGORY_COMMENT_FAIL,
    payload: error,
})

export const runRuleTestResultResults = payload => ({
    type: RUNRULE_TESTRESULT_RESULTS,
    payload: payload,
})
export const runRuleTestResultResultsSuccess = payload => ({
    type: RUNRULE_TESTRESULT_RESULTS_SUCCESS,
    payload: payload,
})

export const runRuleTestResultResultsFail = error => ({
    type: RUNRULE_TESTRESULT_RESULTS_FAIL,
    payload: error,
})

export const getGeneralHistoryResult = payload => ({
    type: GET_GENERAL_HISTORY_RESULT,
    payload: payload || {},
})

export const getGeneralHistoryResultSuccess = payload => ({
    type: GET_GENERAL_HISTORY_RESULT_SUCCESS,
    payload: payload,
})

export const getGeneralHistoryResultFail = error => ({
    type: GET_GENERAL_HISTORY_RESULT_FAIL,
    payload: error
})

export const getPatientResult = payload => ({
    type: GET_PATIENT_RESULT,
    payload: payload || {},
})

export const getPatientResultSuccess = payload => ({
    type: GET_PATIENT_RESULT_SUCCESS,
    payload: payload,
})

export const getPatientResultFail = error => ({
    type: GET_PATIENT_RESULT_FAIL,
    payload: error
})

export const setRowCountSuccess = payload => ({
    type: SET_ROW_COUNT_SUCCESS,
    payload: payload,
})

//get result auditlog
export const getTestResultResultAuditLog = payload => ({
    type: GET_TESTRESULT_RESULT_AUDITLOG,
    payload: payload || {},
})
export const getTestResultResultAuditLogSuccess = payload => ({
    type: GET_TESTRESULT_RESULT_AUDITLOG_SUCCESS,
    payload: payload,
})
export const getTestResultResultAuditLogFail = error => ({
    type: GET_TESTRESULT_RESULT_AUDITLOG_FAIL,
    payload: error,
})

export const getParameterGroupByCodes = payload => ({
    type: GET_PARAMETER_GROUP_BY_CODE_SUCCESS,
    payload: payload,
})

export const getNotifications = payload => ({
    type: GET_RESULT_NOTIFY,
    payload: payload || {}
})

export const getNotificationsSuccess = payload => ({
    type: GET_RESULT_NOTIFY_SUCCESS,
    payload: payload
})

export const getNotificationsFail = error => ({
    type: GET_RESULT_NOTIFY_FAIL,
    payload: error
})

export const createNotify = (payload, callback) => ({
    type: CREATE_RESULT_NOTIFY,
    payload: payload,
    callback: callback,
})

export const createNotifySuccess = payload => ({
    type: CREATE_RESULT_NOTIFY_SUCCESS,
    payload: payload,
})

export const createNotifyFail = error => ({
    type: CREATE_RESULT_NOTIFY_FAIL,
    payload: error,
})

export const warningRuleTestResultResults = payload => ({
    type: WARNING_RULE_TESTRESULT_RESULTS,
    payload: payload,
})
export const warningRuleTestResultResultsSuccess = payload => ({
    type: WARNING_RULE_TESTRESULT_RESULTS_SUCCESS,
    payload: payload,
})

export const warningRuleTestResultResultsFail = error => ({
    type: WARNING_RULE_TESTRESULT_RESULTS_FAIL,
    payload: error,
})
export const clearTestResultDetail = () => ({
    type: CLEAR_TESTRESULT_DETAIL
})
export const testRequestAudit = payload => ({
    type: GET_TEST_REQUEST_AUDITLOG,
    payload: payload,
})

export const testRequestAuditSuccess = payload => ({
    type: GET_TESTRESULT_RESULT_AUDITLOG_SUCCESS,
    payload: payload,
})

export const testRequestAuditFail = error => ({
    type: GET_TESTRESULT_RESULT_AUDITLOG_FAIL,
    payload: error,
})

export const setLoadingChangeDetail = payload => ({
    type: SET_LOADING_CHANGE_RESULT_DETAIL,
    payload: payload || false,
})

export const setScrollResultDetail = value => ({
    type: SET_SCROLL_RESULT_DETAIL,
    payload: value,
})

export const setFastValidate = (payload) => ({
    type: SET_FAST_VALID,
    payload,
})

export const userValidateChange = (payload) => ({
    type: USER_VALIDATE,
    payload,
})

export const clearUserValidate = () => ({
    type: CLEAR_USER_VALIDATE
})

export const getPathologyResult = (payload, callback) => ({
    type: GET_PATHOLOGY_RESULT,
    payload: payload,
    callback: callback,
})

export const getPathologyResultSuccess = payload => ({
    type: GET_PATHOLOGY_RESULT_SUCCESS,
    payload: payload,
})

export const getPathologyResultFail = error => ({
    type: GET_PATHOLOGY_RESULT_FAIL,
    payload: error,
})

export const createPathologyResult = (payload, callback) => ({
    type: CREATE_PATHOLOGY_RESULT,
    payload: payload,
    callback: callback,
})

export const createPathologyResultSuccess = payload => ({
    type: CREATE_PATHOLOGY_RESULT_SUCCESS,
    payload: payload,
})

export const createPathologyResultFail = error => ({
    type: CREATE_PATHOLOGY_RESULT_FAIL,
    payload: error,
})

export const updatePathologyResult = (payload, callback) => ({
    type: UPDATE_PATHOLOGY_RESULT,
    payload: payload,
    callback: callback,
})

export const updatePathologyResultSuccess = payload => ({
    type: UPDATE_PATHOLOGY_RESULT_SUCCESS,
    payload: payload,
})

export const updatePathologyResultFail = error => ({
    type: UPDATE_PATHOLOGY_RESULT_FAIL,
    payload: error,
})

export const updateResultTestVendor = (testVendor, callback) => ({
    type: UPDATE_RESULT_TEST_VENDOR,
    payload: testVendor,
    callback
})

export const updateResultTestVendorSuccess = testVendor => ({
    type: UPDATE_RESULT_TEST_VENDOR_SUCCESS,
    payload: testVendor
})

export const updateResultTestVendorFail = error => ({
    type: UPDATE_RESULT_TEST_VENDOR_FAIL,
    payload: error
})

export const updateResultProfileVendor = (testVendor, callback) => ({
    type: UPDATE_RESULT_PROFILE_VENDOR,
    payload: testVendor,
    callback
})

export const updateResultProfileVendorSuccess = testVendor => ({
    type: UPDATE_RESULT_PROFILE_VENDOR_SUCCESS,
    payload: testVendor
})

export const updateResultProfileVendorFail = error => ({
    type: UPDATE_RESULT_PROFILE_VENDOR_FAIL,
    payload: error
})

export const SignTestResultResults = (data, callback) => ({
    type: SIGN_TESTRESULT_RESULTS,
    payload: data, callback
})
export const SignTestResultResultsSuccess = payload => ({
    type: SIGN_TESTRESULT_RESULTS_SUCCESS,
    payload: payload
})

export const SignTestResultResultsFail = error => ({
    type: SIGN_TESTRESULT_RESULTS_FAIL,
    payload: error,
})

export const UnsignTestResultResults = (data, callback) => ({
    type: UNSIGN_TESTRESULT_RESULTS,
    payload: data, callback
})
export const UnsignTestResultResultsSuccess = payload => ({
    type: UNSIGN_TESTRESULT_RESULTS_SUCCESS,
    payload: payload,
})

export const UnsignTestResultResultsFail = error => ({
    type: UNSIGN_TESTRESULT_RESULTS_FAIL,
    payload: error,
})

//get test result
export const getSendResults = payload => ({
    type: GET_SEND_RESULTS,
    payload: payload || {}
})

export const getSendResultsSuccess = payload => ({
    type: GET_SEND_RESULTS_SUCCESS,
    payload: payload
})

export const getSendResultsFail = error => ({
    type: GET_SEND_RESULTS_FAIL,
    payload: error
})

export const getSendResultsWithoutLoading = (payload, callback) => ({
    type: GET_SEND_RESULTS_WITHOUT_LOADING,
    payload: payload || {},
    callback: callback
})

export const getSendResultsWithoutLoadingSuccess = payload => ({
    type: GET_SEND_RESULTS_WITHOUT_LOADING_SUCCESS,
    payload: payload
})

export const getSendResultsWithoutLoadingFail = error => ({
    type: GET_SEND_RESULTS_WITHOUT_LOADING_FAIL,
    payload: error
})

export const resetSendSearchQuery = () => ({
    type: RESET_SEND_TESTRESULTS_SEARCH_QUERY
})

export const setSendSearchQuery = searchQuery => ({
    type: SET_SEND_TESTRESULTS_SEARCH_QUERY,
    payload: searchQuery
})

export const getPatientRequestWaiting = payload => ({
    type: GET_PATIENT_REQUEST_WAITING,
    payload,
})

// Huyết đồ
export const getHaemogramResult = (payload, callback) => ({
    type: GET_HAEMOGRAM_RESULT,
    payload: payload,
    callback: callback,
})
export const getHaemogramResultSuccess = payload => ({
    type: GET_HAEMOGRAM_RESULT_SUCCESS,
    payload: payload,
})
export const getHaemogramResultFail = error => ({
    type: GET_HAEMOGRAM_RESULT_FAIL,
    payload: error,
})
export const createHaemogramResult = (payload, callback) => ({
    type: CREATE_HAEMOGRAM_RESULT,
    payload: payload,
    callback: callback,
})
export const createHaemogramResultSuccess = payload => ({
    type: CREATE_HAEMOGRAM_RESULT_SUCCESS,
    payload: payload,
})
export const createHaemogramResultFail = error => ({
    type: CREATE_HAEMOGRAM_RESULT_FAIL,
    payload: error,
})
export const updateHaemogramResult = (payload, callback) => ({
    type: UPDATE_HAEMOGRAM_RESULT,
    payload: payload,
    callback: callback,
})
export const updateHaemogramResultSuccess = payload => ({
    type: UPDATE_HAEMOGRAM_RESULT_SUCCESS,
    payload: payload,
})
export const updateHaemogramResultFail = error => ({
    type: UPDATE_HAEMOGRAM_RESULT_FAIL,
    payload: error,
})

export const setTestCategory = (payload) => ({
    type: SET_CATEGORY_FILTER,
    payload,
})
