import CustomBootstrapInlineTable from "components/Common/CustomInlineTable/CustomBootstrapInlineTable"
import CustomBootstrapTableAsync from "components/Common/CustomTable/CustomBootstrapTableAsync"
import { ModuleIds, SAMPLES_DELIVERY_TYPE } from "constant"
import { convertDateFormat } from "helpers/utilities"
import { useEffect, useState } from "react"
import { withTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import ReactTooltip from "react-tooltip"
import ReasonCancel from "./ReasonCancel"
import { CustomSID, LinkCustom } from "components/Common"

let tests = []

const TestTable = ({
  onSelect,
  onSelectAll,
  data,
  onEdit,
  onDelete,
  t,
  loading,
  onChangeRemark,
  onChangeReason,
  delivery,
  onSearch,
  handleTest,
  isCollapse,
  deliveryType,
  resource,
  ...rest
}) => {
  const [testRequestTests, setTestRequestTests] = useState([])
  const [columnExpand, setColumnExpand] = useState([])
  const space = isCollapse ? 0 : 80

  useEffect(() => {
    const test = []
    data.forEach((item, index) => {
      if (item.profileCode) {
        const groupTest = test.find(element =>
          element.profileCode === item.profileCode
          && element.sid === item.sid
          && element.patientId === item.patientId
        )
        if (groupTest) {
          groupTest.children.push(item)
        } else {
          test.push({
            id: index + 1,
            profileCode: item.profileCode,
            profileName: item.profileName,
            sampleType: item.sampleType,
            subSID: item.subSID,
            sid: item.sid,
            patientId: item.patientId,
            patientName: item.patientName,
            reasonName: item.reasonName,
            confirmedUserName: item.confirmedUserName,
            confirmedTime: item.confirmedTime,
            children: [item]
          })
        }
      } else {
        item.id = index + 1
        test.push(item)
      }
    })
    tests = test
    setTestRequestTests([...test])
  }, [data])

  useEffect(() => {
    if (testRequestTests) {
      setColumnExpand([])
    }
  }, [testRequestTests])

  const onTestDelete = (e, rowsDelete, index) => {
    tests.splice(index, 1)
    setTestRequestTests([...tests])
    let result = []
    tests.forEach(item => {
      if (item.children) {
        result = [...result, ...item.children]
      } else {
        result.push(item)
      }
    })
    onDelete({ items: result })
    handleTest({ items: result })
  }

  const onReasonChange = (value, sample) => {
    onChangeReason(value, sample)
  }

  const columns = [
    {
      dataField: "id",
      text: "#",
      hidden: true,
      searchable: false,
    },
    {
      dataField: "",
      text: "#",
      headerStyle: { width: "60px" },
      searchable: false,
      formatter: (cellContent, sample, index) => index + 1
    },
    {
      dataField: "deliveryId",
      text: "deliveryId",
      hidden: true,
      searchable: false,
    },
    {
      dataField: "testName",
      text: t("Test Name"),
      style: { width: SAMPLES_DELIVERY_TYPE.Cancelation ? '200px' : '260px' },
      formatter: (cellContent, code, index) => (
        <>
          {
            (code.children && code.children.length > 0) &&
            <div>
              <i className="fas fa-arrow-right"
                style={code.children && code.children.length > 0 ? { color: "#556ee6" } : {}}></i>
              <span className="px-2" style={{ fontWeight: 'bold' }}>{code.profileName}</span>
            </div>
          }
          {
            (!code.children) &&
            <div>
              <span className="px-2">{code.testName}</span>
            </div>
          }
        </>
      ),
    },
    {
      dataField: "testCode",
      text: t("Test Code"),
      searchable: false,
      style: { width: SAMPLES_DELIVERY_TYPE.Cancelation ? '150px' : '260px' },
      formatter: (cellContent, code, index) => {
        return (
          <>
            {
              (code.children && code.children.length > 0) &&
              <span >{code.profileCode}</span>
            }
            {
              (!code.children) &&
              <span >{code.testCode}</span>
            }
          </>
        )
      },
    },
    {
      dataField: "sid",
      text: t("SID"),
      style: { width: '155px' },
      formatter: (cellContent, item, index) => {
        return <CustomSID
          sid={item.sid}
          textOnly={true}
        />
      }
    },
    {
      dataField: "subSID",
      text: t("SubSID"),
      style: { width: '40px' },
      formatter: (cellContent, item, index) => {
        return (
          <span>
            {item?.children?.length === 0 && cellContent != 0 && cellContent}
          </span>
        )
      },
    },
    {
      dataField: "sampleType",
      text: t("Sample Code"),
      style: { width: '100px' },
    },
    {
      dataField: "patientName",
      text: t("Patient"),
      style: { width: '170px' },
      formatter: (cellContent, sample, index) => (
        // <Link to={`/Patient/${sample.patientId}/view`} target="_blank">
        //   {cellContent}
        // </Link>
        <LinkCustom resource={ModuleIds.Patient} link={`/Patient/${sample.patientId}/view`} content={cellContent} />
      ),
    },
    ...((deliveryType === SAMPLES_DELIVERY_TYPE.Cancelation) ?
      [{
        dataField: "reasonName",
        text: t("Reason"),
        style: { width: '200px' },
        formatter: (cellContent, sample, index) => {
          return deliveryType !== SAMPLES_DELIVERY_TYPE.Cancelation ?
            cellContent
            : <ReasonCancel sample={sample} data={sample} onChangeReason={onReasonChange} />
        }
      },
      {
        dataField: "confirmedUserName",
        text: t("CancelName"),
        searchable: false,
        style: { width: '150px' },
        formatter: (cellContent, sample, index) => {
          return (
            // <Link to={`/User/${sample.confirmedUserId}/view`} target="_blank">
            //   {cellContent}
            // </Link>
            <LinkCustom resource={ModuleIds.User} link={`/User/${sample.confirmedUserId}/view`} content={cellContent} />
          )
        }
      },
      {
        dataField: "confirmedTime",
        text: t("CancelTime"),
        style: { width: '150px' },
        searchable: false,
        formatter: (cellContent, user, index) => convertDateFormat(cellContent, "HH:mm DD-MM-YYYY"),
      },
      {
        dataField: "action",
        text: "",
        isDummyField: true,
        headerStyle: { width: "60px" },
        style: { width: 60 },
        formatter: (cellContent, test, index) => (
          deliveryType === SAMPLES_DELIVERY_TYPE.Cancelation ? (<div className="d-flex gap-3">
            <Link
              to="#"
              className="text-danger"
              onClick={e => { onTestDelete(e, test, index) }}
            >
              <i className="mdi mdi-delete font-size-18"
                data-tip
                data-for={"deletetooltip" + index} />
              <ReactTooltip placement="top" id={"deletetooltip" + index} effect="solid">
                {t('common:Delete')}
              </ReactTooltip>
            </Link>
          </div>) : ''
        ),
      }] : []),
  ]

  const columnChild = (rowIndex) => [
    {
      dataField: "id",
      text: "#",
      hidden: true,
      searchable: false,
    },
    ...(deliveryType === SAMPLES_DELIVERY_TYPE.Cancelation ? [{
      dataField: "",
      text: "#",
      headerStyle: { width: "40px" },
      searchable: false,
    }] : []),
    {
      dataField: "",
      text: "#",
      headerStyle: { width: "60px" },
      searchable: false,
      formatter: (cellContent, sample, index) => `${rowIndex + 1}.${index + 1}`
    },
    {
      dataField: "deliveryId",
      text: "deliveryId",
      hidden: true,
      searchable: false,
    },
    {
      dataField: "testName",
      text: t("Test Name"),
      style: { width: '200px' },
      formatter: (cellContent, sample, index) => (
        <div className="d-flex gap-1">
          <span>-</span>
          <span>{sample.testName}</span>
        </div>

      )
    },
    {
      dataField: "testCode",
      text: t("Test Code"),
      searchable: false,
      style: { width: '150px' },
    },
    {
      dataField: "sid",
      text: t("SID"),
      style: { width: '160px' },
      formatter: (cellContent, item, index) => {
        return <CustomSID
          sid={item.sid}
          textOnly={true}
        />
      }
    },
    {
      dataField: "subSID",
      text: t("SubSID"),
      style: { width: '40px' },
      formatter: (cellContent, item, index) => {
        return (
          <span>
            {cellContent != 0 && cellContent}
          </span>
        )
      },
    },
    {
      dataField: "sampleType",
      text: t("common:Code"),
      style: { width: '100px' },
    },
    {
      dataField: "patientName",
      text: t("Patient"),
      style: { width: '170px' },
      formatter: (cellContent, sample, index) => (
        // <Link to={`/Patient/${sample.patientId}/view`} target="_blank">
        //   {cellContent}
        // </Link>
        <LinkCustom resource={ModuleIds.Patient} link={`/Patient/${sample.patientId}/view`} content={cellContent} />
      ),
    },
    ...((deliveryType === SAMPLES_DELIVERY_TYPE.Cancelation) ?
      [{
        dataField: "reasonName",
        text: t("Reason"),
        style: { width: '210px' },
        formatter: (cellContent, sample, index) => ''
        // formatter: (cellContent, sample, index) => {
        //   return sample.profileCode || deliveryType !== SAMPLES_DELIVERY_TYPE.Cancelation ?
        //     cellContent
        //     : <ReasonCancel sample={sample} data={sample} onChangeReason={onChangeReason} />
        // }
      },
      {
        dataField: "confirmedUserName",
        text: t("CancelName"),
        searchable: false,
        style: { width: '140px' },
        formatter: (cellContent, sample, index) => {
          return (
            // <Link to={`/User/${sample.confirmedUserId}/view`} target="_blank">
            //   {cellContent}
            // </Link>
            <LinkCustom resource={ModuleIds.User} link={`/User/${sample.confirmedUserId}/view`} content={cellContent} />
          )
        }
      },
      {
        dataField: "confirmedTime",
        text: t("CancelTime"),
        style: { width: '150px' },
        searchable: false,
        formatter: (cellContent, user, index) => convertDateFormat(cellContent, "HH:mm DD-MM-YYYY"),
      },
      {
        dataField: "action",
        text: "",
        isDummyField: true,
        style: { width: "80px" },
      }] : []),
  ]

  const UpdateExpandColumn = (code, row) => {
    let children = row['children'] || []
    if (children.length == 0)
      return;
    let result = columnExpand
    let ind = result.findIndex(x => x == code)
    if (ind < 0) {
      result.push(code)
    }
    else {
      result = result.filter(x => x != code)
    }
    setColumnExpand([...result])
  }

  const getNonExpand = () => {
    let res = []
    res = testRequestTests.filter(x => !x.children || x.children.length == 0).map(x => x.id)
    res.push(-1)
    return res
  }

  const expandRow = {
    renderer: (row, rowIndex) => {
      return (
        <div className="table-expand-custom-header table-expand-custom">
          <CustomBootstrapInlineTable
            columns={columnChild(rowIndex)}
            data={row.children}
            isScrollable
            expandRow={expandRow}
            loading={loading}
            isTableScrollX={true}
            searchText={""}
            draggable
            keyField={"id"}
            {...rest}
          />
        </div>
      )
    },
    nonExpandable: getNonExpand(),
    parentClassName: (isExpanded, row, rowIndex) => {
      return columnExpand.findIndex(x => x == row.id) >= 0 ? 'row-expanded' : 'row-noneexpanded'
    },
    expanded: columnExpand
  }

  return (
    <div className="table-expand-custom table-delivery">
      <CustomBootstrapTableAsync
        onSelect={onSelect}
        onSelectAll={onSelectAll}
        isSelected
        showSelectRow={deliveryType === SAMPLES_DELIVERY_TYPE.Cancelation}
        columns={columns}
        data={testRequestTests}
        expandRow={expandRow}
        isEnableLoadInfo={true}
        paging={{
          dataSize: testRequestTests.length,
        }}
        customSize={testRequestTests.length}
        keyField={"id"}
        updatedTime={new Date()}
        placeholder={t('deliveryPage:Search Test')}
        onRowClick={(e, row, rowIndex) => {
          UpdateExpandColumn(row.id, row)
        }}
        style={{ height: window.innerHeight >= 900 ? `${(window.innerHeight - 440) + space}px` : `${(window.innerHeight - 240) + space}px` }}
        {...rest}
      />
    </div>
  )
}

export default withTranslation(["deliveryPage", "common"])(TestTable)
