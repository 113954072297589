import AvForm from "availity-reactstrap-validation/lib/AvForm"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { connect, useSelector } from "react-redux"
import { Col, Row, TabContent, TabPane } from "reactstrap"

import {
  Check,
  CollapsibleModule,
  CollapsibleModuleWrapper,
  CustomButton,
  CustomButtonGroup,
  CustomCheckbox,
  CustomNav,
  CustomNavLink,
} from "components/Common"

//Import actions
import { getResourceActions, getResources } from "store/users/resource/actions"
import {
  getModulesByRoleId,
  getPermissionsByRoleId,
  getRoleStatistic,
  updateModules,
  updatePermissions,
} from "store/users/role/actions"

import { ModuleIds, permissionType, resourceType } from "constant"
import { isEmpty } from "lodash"
import { withTranslation } from "react-i18next"
import { withRouter } from "react-router-dom"

const RESOURCE = ModuleIds.RolePemisssion

const TabPermission = ({
  onGetModulesByRoleId,
  roleId,
  modules,
  resources,
  actions,
  permissions,
  onGetResources,
  onGetResourceActions,
  onGetPermissionsByRoleId,
  onUpdatePermissions,
  customActiveTab,
  onGetRoleStatistic,
  t,
}) => {
  const updating = useSelector(state => state.common.updating)
  const [collapsesOpen, setCollapsesOpen] = useState({})
  const [navCollapsesOpen, setNavCollapsesOpen] = useState({})
  const [models, setModels] = useState({ 1: {} })
  const [selectedResource, setSelectedResource] = useState()
  const [isLoading, setIsLoading] = useState(false);
  const fetchModules = () => {
    const query = {
      type: "M2",
      key: resourceType.main,
    }
    onGetModulesByRoleId({
      query,
      roleId,
      callback: () => { },
    })
  }

  const fetchModulesChildren = parentId => {
    const query = {
      type: "M3",
      ...parentId,
    }
    onGetModulesByRoleId({
      query,
      roleId,
      callback: () => { },
    })
  }

  const fetchResources = parentId => {
    const query = {
      typeText: resourceType.BasicSetup,
      type: ["Page", "Tab"],
      ...parentId,
    }
    onGetResources({ query, callback: resourceHandler })
  }

  const fetchResourcesOther = parentId => {
    const query = {
      type: "Other",
      ...parentId,
    }
    onGetResources({ query })
  }

  const fetchResourcesReportSetup = parentId => {
    const query = {
      type: "Report",
      ...parentId,
    }
    onGetResources({ query, callback: resourceHandler })
  }

  const fetchResourceActions = resourceId => {
    const query = {
      status: true,
      sort: "displayOrder:asc",
    }
    onGetResourceActions({ query, resourceId })
  }

  const fetchRolePermissions = moduleId => {
    onGetPermissionsByRoleId({
      roleId,
      moduleId,
      callback: permissions => moduleHandler(permissions, moduleId),
    })
  }

  const onClickNav = tab => {
    setModels({ 1: {} })
    // fetch Basic Setup
    fetchResources({ parentId: tab })
    // fetch Related object
    fetchResourcesOther({ parentId: tab })
    fetchResourcesReportSetup({ parentId: tab })
  }

  const resourceHandler = resources => {
    resources.forEach(resource => {
      fetchResourceActions(resource.id)
      // fetch Permissions by moduleId
      fetchRolePermissions(resource.id)
    })
  }

  const permissionsHandler = permissions => {
    for (let _permission of permissions) {
      const moduleId = _permission.resourceId
      fetchRolePermissions(moduleId)
    }
  }

  const moduleHandler = (permissions, id) => {
    const result = {}
    permissions?.map(permission => (result[permission.resourceActionId] = true))
    setModels(prev => ({ ...prev, [id]: result }))
    setSelectedResource(id)
    setIsLoading(false)
  }

  const onToggle = id => {
    const isOpened = collapsesOpen[id]
    // fetch resource type = M3
    if (!isOpened) fetchModulesChildren({ parentId: id })
    // if (collapsesOpen[id]) return
    setCollapsesOpen(prev => ({ [id]: !prev[id] }))
  }

  const onValidSubmit = (e, values) => {
    const resourcesIds = [],
      request = []
    const selectedArray = []

    // get resourceIds
    Object.keys(values).forEach(key => {
      const id = key.substring(0, key.indexOf("_"))
      // getResourceIds & push selected object into selectedArray
      if (id) resourcesIds.push(id)
      else if (values[key])
        selectedArray.push({ resourceActionId: key, selected: values[key] })
    })


    // create requestObject
    resourcesIds.forEach(_res => {
      const resource = { resourceId: _res }
      const permissionsProp = []

      // get permission by resourceId
      const varPermissions = permissions[_res]
      // check if permission exists
      selectedArray
        .filter(
          _selectedAction =>
            actions[_res]?.findIndex(
              _action => _selectedAction.resourceActionId === _action.id + ""
            ) >= 0
        )
        .forEach(_val => {
          const _actionId = _val.resourceActionId
          const _permissionIdx = varPermissions.findIndex(
            _perm => _perm.resourceActionId === _actionId
          )

          // if not exists set id = null
          if (_permissionIdx >= 0)
            permissionsProp.push(varPermissions[_permissionIdx])
          else
            permissionsProp.push({
              id: null,
              resourceActionId: _actionId,
            })

          return _val
        })

      resource["permissions"] = permissionsProp
      request.push(resource)
    })


    onUpdatePermissions({
      permissions: request,
      roleId,
      callback: () => {
        onGetRoleStatistic(roleId)
        permissionsHandler(request)
      },
    })
  }

  const handleOnSelectAll = (value, resourceId) => {
    const updatedModels = {}
    const actionsArray = actions[resourceId]
    actionsArray.forEach(_action => (updatedModels[_action.id] = value))

    setModels(prev => ({ ...prev, [resourceId]: updatedModels }))
  }

  const handleOnSelect = (value, resourceId, actionId) => {
    if (!value) {
      delete models[resourceId][actionId]
    }
    else {
      models[resourceId] = { ...models[resourceId], [actionId]: value }
    }
    setModels({ ...models })
  }

  const isChecked = (name) => {
    const data = models[name];
    const keysArray = data && Object.keys(data);
    const objectLength = keysArray && keysArray.length;
    return objectLength == actions[name]?.length;
  }
  const updatedPermissions = permissions => {
    Object.keys(permissions).forEach(_key =>
      moduleHandler(permissions[_key], _key)
    )
  }

  const cancelHandler = () => {
    setIsLoading(true);
    setModels(prev => ({ ...prev, [selectedResource]: {} }))
    fetchRolePermissions(selectedResource)
  }

  useEffect(() => {
    if (customActiveTab === "3") fetchModules()
  }, [customActiveTab])

  useEffect(() => {
    updatedPermissions(permissions)
  }, [permissions])

  return (
    <>
      <CollapsibleModuleWrapper
        onToggle={onToggle}
        defaultTab={modules[resourceType.M2]?.[resourceType.main]?.[0]?.id}
      >
        {modules[resourceType.M2]?.[resourceType.main].map((_module, _idx) => {
          const { resourceName: title, id } = _module,
            key = _idx
          return (
            <CollapsibleModule
              key={key}
              // onToggle={() => onToggle(id)}
              title={t(`sidebar:${title}`)}
              tabId={id + ""}
            >
              <Row>
                <CustomNav
                  vertical
                  tabs
                  className="vertical-tabs"
                  onToggle={onClickNav}
                  defaultTab={modules[resourceType.M3]?.[id]?.[0]?.id}
                  tabContents={activeTab => (
                    <Col>
                      <TabContent
                        activeTab={activeTab}
                        className="p-3 text-muted"
                      >
                        {modules[resourceType.M3]?.[id]?.map(
                          ({ resourceName: label, id: tabId }) => {
                            const basicSetups =
                              resources[resourceType.BasicSetup]?.[tabId]
                            const basicSetupExists = basicSetups?.length > 0

                            const relatedObjects =
                              resources[resourceType.Other]?.[tabId]
                            const relatedObjectExists =
                              relatedObjects?.length > 0

                            const ReportSetups =
                              resources[resourceType.ReportSetup]?.[tabId]
                            const ReportSetupExists = ReportSetups?.length > 0
                            return (
                              <TabPane key={tabId} tabId={tabId}>
                                <AvForm
                                  onValidSubmit={(e, values) =>
                                    onValidSubmit(e, values, tabId)
                                  }
                                  model={models[tabId]}
                                >
                                  {basicSetupExists && <h6>{t('Basic Setup')}</h6>}
                                  {basicSetups?.map(
                                    ({ resourceName: label, id: name }) => {
                                      const checked = isChecked(name)
                                      return (
                                        <Row
                                          key={name}
                                          className="border border-2 p-2 tab-permissions"
                                        >
                                          {!isLoading &&
                                            <React.Fragment>
                                              <Col sm={3}>
                                                <CustomCheckbox
                                                  label={t(`sidebar:${label}`)}
                                                  name={name + "_resource"}
                                                  direction="left"
                                                  onChange={value =>
                                                    handleOnSelectAll(value, name)
                                                  }
                                                  checked={checked}
                                                />
                                              </Col>
                                              <Col sm={9}>
                                                <Row>
                                                  {actions[name]?.map(
                                                    ({ action, id: actionId }) => {
                                                      const checked = !isEmpty(
                                                        models[name]
                                                      )
                                                        ? models[name][actionId]
                                                        : false

                                                      return (
                                                        <Col key={actionId} sm={3}>
                                                          <CustomCheckbox
                                                            label={t(`common:${action}`)}
                                                            name={actionId + ""}
                                                            direction="left"
                                                            checked={checked}
                                                            onChange={value =>
                                                              handleOnSelect(value, name, actionId)
                                                            }
                                                          />
                                                        </Col>
                                                      )
                                                    }
                                                  )}
                                                </Row>
                                              </Col>
                                            </React.Fragment>
                                          }
                                          {isLoading &&
                                            <React.Fragment>
                                              <Col sm={3}>
                                                <CustomCheckbox
                                                  label={t(`sidebar:${label}`)}
                                                  name={name + "_resource"}
                                                  direction="left"
                                                  onChange={value =>
                                                    handleOnSelectAll(value, name)
                                                  }
                                                  checked={checked}
                                                />
                                              </Col>
                                              <Col sm={9}>
                                                <Row>
                                                  {actions[name]?.map(
                                                    ({ action, id: actionId }) => {
                                                      const checked = !isEmpty(
                                                        models[name]
                                                      )
                                                        ? models[name][actionId]
                                                        : false

                                                      return (
                                                        <Col key={actionId} sm={3}>
                                                          <CustomCheckbox
                                                            label={t(`common:${action}`)}
                                                            name={actionId + ""}
                                                            direction="left"
                                                            checked={checked}
                                                            onChange={value =>
                                                              handleOnSelect(value, name, actionId)
                                                            }
                                                          />
                                                        </Col>
                                                      )
                                                    }
                                                  )}
                                                </Row>
                                              </Col>
                                            </React.Fragment>
                                          }
                                        </Row>
                                      )
                                    }
                                  )}
                                  <br />
                                  {relatedObjectExists && (
                                    <h6>Related Object</h6>
                                  )}
                                  {relatedObjects?.map(
                                    ({ resourceName: label, id: name }) => {
                                      const checked = isChecked(name)
                                      return (
                                        <Row
                                          key={name}
                                          className="border border-2 p-2 tab-permissions"
                                        >
                                          {!isLoading &&
                                            <React.Fragment>
                                              <Col sm={3}>
                                                <CustomCheckbox
                                                  label={t(`sidebar:${label}`)}
                                                  name={name + ""}
                                                  direction="left"
                                                  checked={checked}
                                                />
                                              </Col>
                                              <Col sm={9}>
                                                <Row>
                                                  {actions[name]?.map(
                                                    ({ action, id: actionId }) => {
                                                      const checked = !isEmpty(
                                                        models[name]
                                                      )
                                                        ? models[name][actionId]
                                                        : false

                                                      return (
                                                        <Col key={actionId} sm={3}>
                                                          <CustomCheckbox
                                                            label={t(`common:${action}`)}
                                                            name={actionId + ""}
                                                            direction="left"
                                                            checked={checked}
                                                          />
                                                        </Col>
                                                      )
                                                    }
                                                  )}
                                                </Row>
                                              </Col>
                                            </React.Fragment>
                                          }
                                          {isLoading &&
                                            <React.Fragment>
                                              <Col sm={3}>
                                                <CustomCheckbox
                                                  label={t(`sidebar:${label}`)}
                                                  name={name + ""}
                                                  direction="left"
                                                  checked={checked}
                                                />
                                              </Col>
                                              <Col sm={9}>
                                                <Row>
                                                  {actions[name]?.map(
                                                    ({ action, id: actionId }) => {
                                                      const checked = !isEmpty(
                                                        models[name]
                                                      )
                                                        ? models[name][actionId]
                                                        : false

                                                      return (
                                                        <Col key={actionId} sm={3}>
                                                          <CustomCheckbox
                                                            label={t(`common:${action}`)}
                                                            name={actionId + ""}
                                                            direction="left"
                                                            checked={checked}
                                                            onChange={value =>
                                                              handleOnSelect(value, name, actionId)
                                                            }
                                                          />
                                                        </Col>
                                                      )
                                                    }
                                                  )}
                                                </Row>
                                              </Col>
                                            </React.Fragment>
                                          }
                                        </Row>
                                      )
                                    }
                                  )}
                                  {ReportSetupExists && <h6>{t('Report Setups')}</h6>}
                                  {ReportSetups?.map(
                                    ({ resourceName: label, id: name }) => {
                                      const checked = isChecked(name)
                                      return (
                                        <Row
                                          key={name}
                                          className="border border-2 p-2 tab-permissions"
                                        >
                                          {!isLoading &&
                                            <React.Fragment>
                                              <Col sm={3}>
                                                <CustomCheckbox
                                                  label={t(`sidebar:${label}`)}
                                                  name={name + "_resource"}
                                                  direction="left"
                                                  onChange={value =>
                                                    handleOnSelectAll(value, name)
                                                  }
                                                  checked={checked}
                                                />
                                              </Col>
                                              <Col sm={9}>
                                                <Row>
                                                  {actions[name]?.map(
                                                    ({ action, id: actionId }) => {
                                                      const checked = !isEmpty(
                                                        models[name]
                                                      )
                                                        ? models[name][actionId]
                                                        : false
                                                      return (
                                                        <Col key={actionId} sm={3}>
                                                          <CustomCheckbox
                                                            label={t(`common:${action}`)}
                                                            name={actionId + ""}
                                                            direction="left"
                                                            checked={checked}
                                                            onChange={value =>
                                                              handleOnSelect(value, name, actionId)
                                                            }
                                                          />
                                                        </Col>
                                                      )
                                                    }
                                                  )}
                                                </Row>
                                              </Col>
                                            </React.Fragment>
                                          }
                                          {isLoading &&
                                            <React.Fragment>
                                              <Col sm={3}>
                                                <CustomCheckbox
                                                  label={t(`sidebar:${label}`)}
                                                  name={name + "_resource"}
                                                  direction="left"
                                                  onChange={value =>
                                                    handleOnSelectAll(value, name)
                                                  }
                                                  checked={checked}
                                                />
                                              </Col>
                                              <Col sm={9}>
                                                <Row>
                                                  {actions[name]?.map(
                                                    ({ action, id: actionId }) => {
                                                      const checked = !isEmpty(
                                                        models[name]
                                                      )
                                                        ? models[name][actionId]
                                                        : false
                                                      return (
                                                        <Col key={actionId} sm={3}>
                                                          <CustomCheckbox
                                                            label={t(`common:${action}`)}
                                                            name={actionId + ""}
                                                            direction="left"
                                                            checked={checked}
                                                            onChange={value =>
                                                              handleOnSelect(value, name, actionId)
                                                            }
                                                          />
                                                        </Col>
                                                      )
                                                    }
                                                  )}
                                                </Row>
                                              </Col>
                                            </React.Fragment>
                                          }
                                        </Row>
                                      )
                                    }
                                  )}
                                  <br />
                                  {(basicSetupExists || ReportSetupExists ||
                                    relatedObjectExists) && (
                                      <CustomButtonGroup className="justify-content-end mt-2">
                                        <Check
                                          resource={RESOURCE}
                                          permission={permissionType.U}
                                        >
                                          <CustomButton
                                            type="button"
                                            // onClick={toggle}
                                            onClick={cancelHandler}
                                            data-dismiss="modal"
                                            text={t("common:Cancel")}
                                            isEdit
                                            className="button-width"
                                          />{" "}
                                          <CustomButton
                                            type="submit"
                                            color="primary"
                                            className="save-user button-width"
                                            text={t("common:Save")}
                                            isEdit
                                          />
                                        </Check>
                                      </CustomButtonGroup>
                                    )}
                                </AvForm>
                              </TabPane>
                            )
                          }
                        )}
                      </TabContent>
                    </Col>
                  )}
                >
                  {modules[resourceType.M3]?.[id]?.map(
                    ({ resourceName: label, id: tabId }) => {
                      return (
                        <CustomNavLink key={tabId} tabId={tabId} disabled={updating}>
                          {t(`sidebar:${label}`)}
                        </CustomNavLink>
                      )
                    }
                  )}
                </CustomNav>
              </Row>
            </CollapsibleModule>
          )
        })}
      </CollapsibleModuleWrapper>
    </>
  )
}

TabPermission.propTypes = {
  resources: PropTypes.object,
  actions: PropTypes.object,
  modules: PropTypes.object,
  onGetResources: PropTypes.func,
  onGetModulesByRoleId: PropTypes.func,
  onUpdateModules: PropTypes.func,
  onGetResourceActions: PropTypes.func,
  onGetPermissionsByRoleId: PropTypes.func,
  onUpdatePermissions: PropTypes.func,
  onGetRoleStatistic: PropTypes.func,
}

const mapStateToProps = ({ resource, role }) => ({
  resources: resource.resources,
  actions: resource.actions,
  modules: role.modules,
  permissions: role.permissions,
})

const mapDispatchToProps = dispatch => ({
  onGetResources: payload => dispatch(getResources(payload)),
  onGetModulesByRoleId: payload => dispatch(getModulesByRoleId(payload)),
  onGetResourceActions: payload => dispatch(getResourceActions(payload)),
  onGetPermissionsByRoleId: payload =>
    dispatch(getPermissionsByRoleId(payload)),
  onUpdateModules: payload => dispatch(updateModules(payload)),
  onUpdatePermissions: payload => dispatch(updatePermissions(payload)),
  onGetRoleStatistic: id => dispatch(getRoleStatistic(id)),
})

export default connect(mapStateToProps, mapDispatchToProps)
  (withRouter(withTranslation(["rolePage", "common"])(TabPermission)))
