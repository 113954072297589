import { AvForm } from "availity-reactstrap-validation"

import { Col, Label, ModalBody, ModalFooter, Row } from "reactstrap"

import {
  CustomAvField,
  CustomButton,
  CustomButtonGroup,
  CustomCheckbox,
  CustomModal,
  CustomSelect,
  CustomSelectGroup,
  TextField,
} from "components/Common"
import { TestProfileTypeText, parameterCode, TestProfileType } from "constant/utility"

//i18n
import { getCodesByParameterId } from "helpers/app-backend"
import { getI18nextLng, getInvalidMessageI18n } from "helpers/utilities"
import { isEmpty } from "lodash"
import { useEffect, useMemo, useState } from "react"
import { withTranslation } from "react-i18next"

const ProfileModal = ({
  modal,
  toggle,
  isEdit,
  onValidSubmit,
  data,
  formEl,
  t,
  resource
}) => {
  const title =
    (!!isEdit ? t("Edit Profile") : t("Add Profile"));
  if (isEdit && isEmpty(data)) return null
  let lang = getI18nextLng()
  const [profileType, setProfileType] = useState([])
  const [listProfileType, setListProfileType] = useState([])
  const [type, setType] = useState(data.type || "1")

  const fectchProfileType = async () => {
    const query = { lang }
    let res = await getCodesByParameterId(
      parameterCode.TESTPROFILE_TYPE_CODE,
      query
    )
    res = res.filter(
      r =>
        r.inUse &&
        [
          TestProfileTypeText.PROFILE,
          TestProfileTypeText.GROUP
        ].findIndex(x => x == r.code) >= 0
    )
    setListProfileType(res)
  }

  useMemo(() => {
    fectchProfileType()
  }, [])
  useEffect(() => {
    setProfileType(data.type)
  }, [data.type])
  return (
    <CustomModal modal={modal} title={title} onToggle={toggle}>
      <ModalBody>
        <AvForm
          ref={formEl}
          id="profileForm"
          onValidSubmit={(e, values) => {
            onValidSubmit(e, values, type)
          }}
          model={data}
        >
          <CustomAvField name="id" type="hidden" value={data.id || ""} />
          <Row>
            <Col xs="6">
              <div className="mb-3">
                <CustomAvField
                  name="code"
                  type="text"
                  required
                  errorMessage={getInvalidMessageI18n(t, "Profile Code")}
                  validate={{
                    required: { value: true },
                  }}
                  value={data.code || ""}
                  label={t("Profile Code")}
                  detected={isEdit}
                  disabled={isEdit}
                />
              </div>
            </Col>
            <Col xs="6">
              <div className="mb-3">
                <CustomAvField
                  name="profileName"
                  type="text"
                  required
                  errorMessage={getInvalidMessageI18n(t, "Profile Name")}
                  validate={{
                    required: { value: true },
                  }}
                  value={data.profileName || ""}
                  label={t("Profile Name")}
                  detected={isEdit}
                />
              </div>
            </Col>

            <Col xs="6">
              <div className="mb-3">
                <Label for="example">{t("common:Type")} </Label>
                {
                  listProfileType.length <= 3
                    ?
                    <CustomButtonGroup className="justify-content-start">
                      {listProfileType.map((item, index) => (
                        <CustomButton
                          text={t(`common:${item.message}`)}
                          name="type"
                          type="button"
                          onClick={e => {
                            setType(item.code)
                          }}
                          color={item.code == type ? "primary" : "light"}
                          className="button-width"
                          value={item.code}
                          key={index}
                          disabled={isEdit}
                        />
                      ))}
                    </CustomButtonGroup>
                    :
                    <CustomSelect
                      name="type"
                      value={data.type || 0}
                      code={parameterCode.TESTPROFILE_TYPE_CODE}
                      label={t("Profile Type")}
                      detected={isEdit}
                    />
                }
              </div>
            </Col>
            <Col xs="6">
              <div className="form-check form-switch" style={{ gap: 20, width: '100%', padding: 0, marginLeft: 0 }}>
                <CustomCheckbox
                  type="checkbox"
                  direction={"down"}
                  name="profileStatus"
                  // value={value === 'True'}
                  checked={data.status ?? true}
                  detected={isEdit}
                  label={`${t("common:Active")} ?`}
                />
              </div>
            </Col>
            <Col xs="6">
              <div className="mb-3">
                <CustomSelect
                  name="sampleType"
                  value={parseInt(type) === TestProfileTypeText.GROUP ? "" : data.sampleType}
                  code={parameterCode.TESTPROFILE_SAMPLE_TYPE_CODE}
                  label={t("Sample Type")}
                  detected={isEdit}
                  readOnly={parseInt(type) === TestProfileTypeText.GROUP ? true : false}
                />
              </div>
            </Col>
            <Col xs="6">
              <div className="mb-3">
                <CustomSelectGroup
                  name="category"
                  value={parseInt(type) === TestProfileTypeText.GROUP ? "" : data.category}
                  code={parameterCode.TESTPROFILE_SUB_CATEGORY_CODE}
                  label={t("common:Category")}
                  detected={isEdit}
                  readOnly={parseInt(type) === TestProfileTypeText.GROUP ? true : false}
                />
              </div>
            </Col>
            <Col xs="6">
              <div className="mb-3">
                <CustomAvField
                  name="remark"
                  value={data.remark || ""}
                  label={t("common:Remark")}
                  detected={isEdit}
                  type="textarea"
                  rows="3"
                />
              </div>
            </Col>
            <Col xs="6">
              <div className="mb-3">
                <CustomAvField
                  name="displayOrder"
                  value={data.displayOrder || 0}
                  label={t("Display Order")}
                  detected={isEdit}
                  type="number"
                  validate={{
                    required: { value: true, errorMessage: getInvalidMessageI18n(t, "Display Order") },
                    pattern: { value: '^[0-9]+$', errorMessage: getInvalidMessageI18n(t, "Display Order") },
                  }}
                />
              </div>
            </Col>
          </Row>
          <button type="submit" className="d-none" />
        </AvForm>
      </ModalBody>
      <ModalFooter>
        <CustomButton
          text={t("common:Cancel")}
          type="button"
          onClick={toggle}
          data-dismiss="modal"
          className="button-width"
        />

        <CustomButton
          text={t("common:Save")}
          type="submit"
          color="primary"
          onClick={() => formEl?.current?.submit()}
          className="save-user button-width"
          isEdit
        />
      </ModalFooter>
    </CustomModal>
  )
}

export default withTranslation(["testProfilePage", "common"])(ProfileModal)
